import React from "react";

interface CellPhoneProps {
  phone: string;
}

const CellPhone: React.FC<CellPhoneProps> = ({ phone }) => (
  <p className="mb-5">C: {phone}</p>
);

export default CellPhone;
