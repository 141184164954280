import React, { useState, useEffect } from "react";
import Login from "../../components/user/Login.tsx"; // Adjust the import path as necessary
import Breadcrumbs from "../../components/utils/Breadcrumbs.tsx";
import { Link } from "react-router-dom";
import AdminHome from "./AdminHome.tsx"; // Adjust the import path as necessary

const Admin: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  // uncomment after implementing the API call
  //   const [advisors, setAdvisors] = useState<Advisor[]>([]);
  //   const [filteredAdvisors, setFilteredAdvisors] = useState<Advisor[]>([]);

  const handleLogin = () => {
    // Logic to handle login
    setIsAuthenticated(true);
  };

  return (
    <div className="">
      {!isAuthenticated ? (
        <>
          <h1 className="text-3xl font-bold">Admin Login</h1>
          <Login title={"no title yet"} />
        </>
      ) : (
        <div>
          <AdminHome />
          {/* <Breadcrumbs
            items={[
              { label: "Home", path: "/" },
              { label: "Admin", path: "/super-admin" },
            ]}
          />
          <h1 className="text-3xl font-bold mb-4">Admin Dashboard</h1>
          <Link to="/super-admin/manage-advisors">
            <button className="px-4 py-2 bg-primary text-accent rounded hover:bg-secondary">
              Manage Advisors
            </button>
          </Link> */}
        </div>
      )}
    </div>
  );
};

export default Admin;
