import React, { useState, useEffect } from "react";
import Stage from "../../Helper/LeadComponents/Stage.tsx";
import Tags from "../../Helper/LeadComponents/Tags.tsx";
import CustomFields from "../../Helper/LeadComponents/CustomFields.tsx";
import TaskTracker from "../Task/TaskTracker.tsx";
import AppointmentTracker from "../Appointment/AppointmentTracker.tsx";
import LeadTracker from "../Lead/LeadTracker.tsx";
import axiosInstance from "../../../../api/axiosInstance.tsx";
import DisplayLead from "../Lead/DisplayLead.tsx";

interface LeadData {
  fname: string;
  lname: string;
  email: string;
  phone: string;
  address1: string;
  stage: string;
  tags: string[];
  customFields: string[];
}

const SeniorLeadCard: React.FC = () => {
  const [leadData, setLeadData] = useState<LeadData>({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    address1: "",
    stage: "",
    tags: [],
    customFields: [],
  });

  useEffect(() => {
    axiosInstance.get("/leads/1").then((response) => {
      console.log("lead data", response.data);
      setLeadData(response.data);
    });
  }, []);

  return (
    <div className="p-6 bg-white shadow-lg rounded-md w-full">
      <div className="flex items-center mb-4">
        <h1 className="text-2xl font-bold">Senior Lead Card</h1>
      </div>

      <div className="flex space-x-4">
        {/* Left column */}
        <div className="w-1/4 space-y-4 border p-4 rounded-md mb-4">
          <DisplayLead leadData={leadData} />
          <Stage />
          <Tags />
          <CustomFields />
        </div>
        <div className="w-1/2">
          <LeadTracker />
        </div>

        <div className="w-1/4 space-y-4">
          {/* Task Tracker Section */}
          <h3 className="font-bold">Lead 4-43</h3>
          <div className="border p-4 rounded-lg shadow-md ">
            <TaskTracker />
            <AppointmentTracker />
          </div>
        </div>
      </div>
      <button className="btn-danger">Deactivate User</button>
    </div>
  );
};

export default SeniorLeadCard;
