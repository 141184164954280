// PrivacyPolicy.tsx
import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container py-10 ">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Introduction</h2>
        <p>
          Welcome to Bluejay®! Your privacy is critical to us. This Privacy
          Policy outlines our practices concerning collecting, using, and
          sharing your information through Bluejay®. By using our services, you
          agree to the collection and use of information per this policy.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Information Collection</h2>
        <p>
          <strong>Senior Advisors:</strong> We collect information you provide
          during the registration process, such as your name, email address,
          contact details, and professional license number. This information
          helps us verify your credentials and manage your profile.
        </p>
        <p>
          <strong>Care Champions:</strong> When you register, we collect
          personal details like your name and email address, along with your
          preferences and inquiries regarding senior living options, to tailor
          our services to your needs.
        </p>
        <p>
          <strong>Community Champions:</strong> We gather information about your
          community, including contact information, services offered, and
          details about amenities, which help users make informed decisions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Use of Information</h2>
        <p>We use the information we collect to:</p>
        <ul className="list-disc list-inside ml-4">
          <li>Operate, maintain, and improve our services.</li>
          <li>Connect users with Senior Advisors and Community Champions.</li>
          <li>Respond to your comments, questions, and requests.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">
          Information Sharing and Disclosure
        </h2>
        <p>
          We may share information with third parties who perform services on
          our behalf, such as data analysis and email delivery.
        </p>
        <p>We do not sell your personal information to third parties.</p>
        <p>
          Information may be disclosed if required by law or to protect our
          rights or the rights of others.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Data Security</h2>
        <p>
          We implement security measures to protect against unauthorized access
          to or unauthorized alteration, disclosure, or destruction of data.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">User Rights and Choices</h2>
        <p>
          You can review, amend, or delete your information by logging into your
          account or contacting us directly.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">
          Cookies and Tracking Technologies
        </h2>
        <p>
          We use cookies and similar technologies to track the activity on our
          service and hold certain information to improve user experience.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">
          Changes to the Privacy Policy
        </h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">Contact Information</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at{" "}
          <a href="mailto:info@findhomebluejay.com" className="text-blue-600">
            info@findhomebluejay.com
          </a>
          .
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
