import React from "react";

interface CardProps {
  title: string;
  img: string;
  description: string;
  key: number;
  onClick: () => void;
}

const Card: React.FC<CardProps> = ({ title, img, description, onClick }) => {
  return (
    <div className="m-7 hover:cursor-pointer" onClick={onClick}>
      <div className="text-xl font-bold mb-3">{title}</div>
      <div>
        <img src={img} alt="image " />
      </div>
      <p className="m-4">{description}</p>
    </div>
  );
};

export default Card;
