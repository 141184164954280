import CalendarComponent from "../AdminCommunityChampion/Calendar/Calendar";

function Calendar() {
  return (
    <div>
      <CalendarComponent />
    </div>
  );
}

export default Calendar;
