import React from "react";
interface ClientTestimonialsProps {
  testimonials: string[];
}

const ClientTestimonials: React.FC<ClientTestimonialsProps> = ({
  testimonials,
}) => (
  <div className=" flex items-center mb-4">
    <h3 className="text-lg font-semibold">Client Testimonials:</h3>
    {/* <ul>
      {testimonials.map((testimonial, index) => (
        <li key={index}>{testimonial}</li>
      ))}
    </ul> */}
    <a href="/" className="text-secondary ml-2 hover:underline">
      Read Reviews
    </a>
  </div>
);

export default ClientTestimonials;
