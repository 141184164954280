import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

interface TogglerProps {
  toggleSection: () => void;
  setShowModal: (showTaskModal: boolean) => void;
  openSection: boolean;
  title: string;
  setOpenSection: (openSection: boolean) => void;
}

const Toggler: React.FC<TogglerProps> = ({
  toggleSection,
  setShowModal,
  openSection,
  setOpenSection,
  title,
}) => {
  const location = useLocation();

  if (location.pathname.includes("dashboard")) {
    setOpenSection(true);
    return <h3 className="text-lg font-semibold">{title}</h3>;
  }

  return (
    <div
      className="flex justify-between items-center cursor-pointer"
      onClick={toggleSection}
    >
      <h3 className="text-lg font-semibold">{title}</h3>
      <div className="flex items-center space-x-2">
        <button
          className=" p-2"
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(true);
          }}
        >
          <FontAwesomeIcon
            className="text-primary w-5 h-5 hover:text-secondary"
            icon={faPlusCircle}
          />
        </button>
        <span>
          {openSection ? (
            <FontAwesomeIcon
              className="text-primary w-5 h-5 hover:text-secondary"
              icon={faChevronCircleDown}
            />
          ) : (
            <FontAwesomeIcon
              className="text-primary w-5 h-5 hover:text-secondary"
              icon={faChevronCircleUp}
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default Toggler;
