import { useEffect, useState } from "react";
import DisplayLead from "./Lead/DisplayLead";

interface LeadData {
  fname: string;
  lname: string;
}

const leads: LeadData[] = [
  {
    fname: "John",
    lname: "Doe",
  },
  {
    fname: "Jane",
    lname: "Doe",
  },
  {
    fname: "John",
    lname: "Smith",
  },
];

const SeniorLeadCardOverview = () => {
  const [selectedLeads, setSelectedLeads] = useState<LeadData[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleSelectAll = () => {
    setSelectAllChecked(!selectAllChecked);
    if (selectAllChecked) {
      setSelectedLeads([]);
    } else {
      setSelectedLeads(leads);
    }
  };

  const handleCheckboxChange = (lead: LeadData) => {
    console.log("lead", lead);

    if (selectedLeads.includes(lead)) {
      setSelectedLeads(selectedLeads.filter((l) => l !== lead));
    } else {
      setSelectedLeads([...selectedLeads, lead]);
    }
  };

  return (
    <div className="">
      <div className="flex items-center mb-4">
        <h1 className="text-2xl font-bold">Senior Lead Card Overview</h1>
      </div>
      <label className="mr-4" htmlFor="">
        <input
          type="checkbox"
          checked={selectedLeads.length === leads.length}
          onChange={handleSelectAll}
          className="mr-2"
        />
        Select All
      </label>
      <div className="flex space-x-4">
        {/* Left column */}
        <div className="w-full space-y-4 border p-4 rounded-md mb-4">
          <ul className="space-y-4">
            {leads.map((lead, index) => (
              <div className="flex border p-4 ">
                <input
                  type="checkbox"
                  checked={selectedLeads.includes(lead)}
                  onChange={() => handleCheckboxChange(lead)}
                  className="mr-4"
                />
                <DisplayLead key={index} leadData={lead} />
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SeniorLeadCardOverview;
