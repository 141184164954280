import { useUserContext } from "../../../components/context/UserContext.tsx";

function Favorites() {
  const { likedCommunities } = useUserContext();

  return (
    <div className="container">
      <h2 className="w-1/2 mx-auto text-xxl font-semibold my-4">
        Saved Communitites
      </h2>

      {likedCommunities?.map((community) => (
        <div className="w-1/2 mx-auto flex items-center border rounded-md p-4 space-x-2 mb-2 ">
          <img
            className="h-24 w-24 rounded-full"
            src={"https://via.placeholder.com/150"}
            alt="community image"
          />
          <div>
            <p>{community.Name}</p>
            <p>{community.Phone}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Favorites;
