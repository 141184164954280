import React from "react";
import SeniorAdvisorCard from "../../../advisor/SeniorAdvisorCard";

interface AdvisorProfileProps {
  advisor: {
    photo?: string;
    name?: string;
    title?: string;
    license?: string;
    directPhone?: string;
    cellPhone?: string;
    email?: string;
    credentials?: string[];
    services?: string[];
    awards?: string[];
    testimonials?: string[];
    yearsInBusiness?: number;
    areasServed?: string[];
    followMeLinks?: { platform: string; url: string }[];
    summary?: string;
  };
}

const AdvisorProfile: React.FC<AdvisorProfileProps> = ({ advisor }) => {
  return (
    <div className="flex flex-col md:flex-row p-4  bg-white shadow-md rounded-lg">
      <SeniorAdvisorCard advisor={advisor} />
    </div>
  );
};

export default AdvisorProfile;
