import React, { useState } from "react";
import { useLocation, useNavigate, Outlet, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

interface DrawerContainerProps {
  items: { text: string; route: string; icon: string }[];
  children: React.ReactNode;
}

const DrawerContainer: React.FC<DrawerContainerProps> = ({
  items,
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(true);

  // Handle drawer item click and navigation
  const onSelect = (route: any) => {
    navigate(route);
  };

  // Determine the selected item based on the current location
  const getSelectedItem = () => {
    const currentPath = items.find((item) => item.route === location.pathname);
    return currentPath ? currentPath.text : "Dashboard";
  };

  const selected = getSelectedItem();

  return (
    <div className="flex">
      {/* Left-side Drawer */}
      {/* <div
        className={`transition-transform duration-300 ${
          expanded ? "w-64" : "w-16"
        } bg-secondary text-black flex-shrink-0`}
      >
        <div className="p-4">
          <button
            onClick={() => setExpanded(!expanded)}
            className="text-lg focus:outline-none"
          >
            {expanded ? (
              <FontAwesomeIcon icon={faTimes} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </button>
        </div>

        <ul className="mt-4">
          {items.map((item) => (
            <li
              key={item.text}
              className="p-4 hover:bg-primary hover:text-white"
            >
              <button
                className={`flex items-center space-x-2 ${
                  selected === item.text ? "text-primary font-bold" : ""
                } w-full`}
                onClick={() => onSelect(item.route)}
              >
                <span className="text-xl">{item.icon}</span>
                {expanded && <span>{item.text}</span>}
              </button>
            </li>
          ))}
        </ul>
      </div> */}

      {/* Main Content Area */}
      <div className="flex-grow p-6">{children}</div>
    </div>
  );
};

export default DrawerContainer;
