import React from "react";
import BannerImg from "../../components/utils/BannerImg.tsx";
import bannerImg from "../../assets/banners/Contact us.png";
import img1 from "../../assets/contactUsImg.jpg";
import BannerHeading from "../../components/utils/BannerHeading.tsx";
import AdvisorsGrid from "../../components/advisor/AdvisorsGrid.tsx";

const Contact: React.FC = () => {
  return (
    <div className="mb-10">
      <div className="w-full overflow-hidden">
        <BannerHeading title="Contact Us at Bluejay" width="1/3" />
        <div className="w-full overflow-hidden ">
          <BannerImg imgUrl={bannerImg} />
        </div>
        <div className="flex justify-around mt-7">
          <div className="w-1/2 px-3">
            <h2 className=" text-2xl font-bold py-4 my-7 ">
              We’re Here to Help You Navigate with Confidence
            </h2>
            <p className=" mt-3">
              At Bluejay, we understand that exploring senior living options and
              making important life transitions can be overwhelming. That's why
              our dedicated team is here to offer support, answer your
              questions, and provide the guidance you need to make informed
              decisions with confidence.
            </p>
          </div>
          <img src={img1} alt="board games" className="w-1/3 mt-9" />
        </div>
        <div className="container mt-10">
          <h3 className="text-2xl font-semibold my-4">
            Reach Out Anytime — We’re Ready to Listen
          </h3>

          <p className=" mt-3">
            Whether you’re considering senior living options for yourself or a
            loved one, or if you have questions about the process, our
            knowledgeable and compassionate team is just a phone call or email
            away. We’re committed to providing you with all the resources and
            support you need during this significant phase of life.
          </p>
          <h3 className="text-2xl font-semibold my-4">
            Schedule a Personal Consultation
          </h3>

          <p className=" mt-3">
            If you prefer a more personal touch, schedule a consultation with
            one of our senior living advisors. We can meet over the phone, via
            video call, or in-person to discuss your specific needs and concerns
            in detail.
          </p>
          <AdvisorsGrid initialShowSingleAdvisor={true} />
          <h3 className="text-2xl font-semibold my-4">
            Your Journey, Our Support
          </h3>

          <p className=" mt-3">
            Remember, you’re not alone in this journey. Every question is
            important, and every concern is valid. We’re here to empower you and
            ensure that your transition into senior living is handled with the
            utmost care and professionalism. Reach out today and take the first
            step towards a confident and informed future.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
