import { useState } from "react";

const CommunityTermsAndConditions = () => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  return (
    <>
      <h2 className="text-xl font-semibold mb-4">Terms and Conditions</h2>
      <h3 className="text-lg font-semibold mb-2">Community Champions</h3>
      <p className="mb-2">
        1. Introduction Welcome to Bluejay®. By claiming your community profile
        and using our platform, you agree to adhere to the following terms and
        conditions. Please read them carefully.
      </p>
      <p className="mb-2">2. Account Setup</p>
      <ul className="list-disc pl-5 mb-2">
        <li>
          Profile Management: As a Community Champion, you are responsible for
          maintaining the accuracy and completeness of your community’s profile.
          This includes updating vacancy statuses, amenities, and any other
          relevant information that affects the desirability and correctness of
          your listing.
        </li>
        <li>
          Account Security: You are responsible for maintaining the security of
          your account login information and for all activities that occur under
          your account.
        </li>
      </ul>
      <p className="mb-2">3. Content Ownership and Permission</p>
      <ul className="list-disc pl-5 mb-2">
        <li>
          Content Submission: You agree to only upload content (photos, text,
          videos) that you own or have permission to use and share publicly.
        </li>
        <li>
          Content Accuracy: You certify that all information provided on your
          community’s profile is accurate to the best of your knowledge and that
          you will promptly update such information to maintain its accuracy.
        </li>
      </ul>
      <p className="mb-2">4. Interactions with Users</p>
      <ul className="list-disc pl-5 mb-2">
        <li>
          Communications: You agree to conduct all communications with
          prospective residents and their influencers through Bluejay®, adhering
          to the highest professional standards.
        </li>
        <li>
          Privacy: You must respect the privacy of users and handle all personal
          information shared through the platform with the utmost care and in
          compliance with applicable privacy laws.
        </li>
      </ul>
      <p className="mb-2">
        5. Marketing and Promotions You grant Bluejay® permission to use your
        community’s name, images, and other promotional content for the purpose
        of marketing your community on our platform and potentially through
        other channels such as social media or email marketing.
      </p>
      <p className="mb-2">
        6. Compliance with Laws You agree to comply with all local, state, and
        federal regulations that apply to your operation and representation on
        the Bluejay® platform.
      </p>
      <p className="mb-2">
        7. Termination Bluejay® reserves the right to terminate your access to
        the platform if you fail to comply with these terms, provide inaccurate
        information, or engage in any activity that breaches legal or ethical
        standards.
      </p>
      <p className="mb-2">
        8. Limitation of Liability Bluejay® shall not be liable for any damages
        arising out of your use of the platform or your inability to use the
        platform. You agree to indemnify Bluejay® against any claims or damages
        resulting from your misuse of the platform.
      </p>
      <p className="mb-2">
        9. Changes to Terms Bluejay® reserves the right to modify these terms at
        any time. We will notify you of any significant changes and you will
        have the option to continue or discontinue the use of the service under
        the revised terms.
      </p>
      <p className="mb-2">
        10. Dispute Resolution Any disputes arising under these terms will be
        resolved through binding arbitration in accordance with the rules of the
        American Arbitration Association.
      </p>
    </>
  );
};

export default CommunityTermsAndConditions;
