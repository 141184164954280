import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";

interface AppointmentCardProps {
  appointment: {
    title: string;
    startDate: string;
    startTime: string;
    endTime: string;
  };
  onClick?: () => void;
}

const AppointmentCard: React.FC<AppointmentCardProps> = ({
  appointment,
  onClick,
}) => {
  // Calculate the appointment status based on the start date and time
  const getBackgroundColor = () => {
    const now = new Date();
    const appointmentStart = new Date(
      `${appointment.startDate}T${appointment.startTime}`
    );
    const timeDifference = appointmentStart.getTime() - now.getTime();

    // Time difference in hours
    const hoursUntilStart = timeDifference / (1000 * 60 * 60);

    if (hoursUntilStart < 0) {
      // Appointment is past due
      return "bg-red-100 border-red-400";
    } else if (hoursUntilStart <= 24) {
      // Appointment is within the next 24 hours
      return "bg-yellow-100 border-yellow-400";
    } else {
      // Default background
      return "bg-white border-gray-200";
    }
  };

  return (
    <div
      onClick={onClick}
      className={`hover:cursor-pointer p-4 rounded-lg shadow-sm border flex items-start space-x-3 ${getBackgroundColor()}`}
    >
      {/* Appointment Icon */}
      <div className="flex-shrink-0">
        <FontAwesomeIcon
          icon={faCalendarAlt} // Icon representing an appointment
          className="text-blue-500 text-lg"
        />
      </div>

      {/* Appointment Details */}
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-gray-900">{appointment.title}</p>
        <p className="text-xs text-gray-500 flex items-center space-x-1">
          <FontAwesomeIcon icon={faClock} className="text-gray-400" />
          <span>{appointment.startDate}</span>
        </p>
      </div>

      {/* Appointment Time */}
      <div className="text-xs text-gray-500">
        {appointment.startTime} - {appointment.endTime}
      </div>
    </div>
  );
};

export default AppointmentCard;
