import React from "react";

const SendEmail: React.FC = () => {
  return (
    <div className="border p-4 rounded-md mb-4 bg-white">
      <input
        type="text"
        className="w-full p-2 border rounded-md mb-2"
        placeholder="From"
      />
      <input
        type="text"
        className="w-full p-2 border rounded-md mb-4"
        placeholder="To"
      />
      <textarea
        className="w-full p-2 border rounded-md mb-4"
        placeholder="Type your email here..."
      />
      <button className="px-4 py-2 btn-quaternary text-white rounded-md">
        Send Email
      </button>
    </div>
  );
};

export default SendEmail;
