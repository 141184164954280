import React from "react";

interface DirectPhoneProps {
  phone: string;
}

const DirectPhone: React.FC<DirectPhoneProps> = ({ phone }) => (
  <p className="">D: {phone}</p>
);

export default DirectPhone;
