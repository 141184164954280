import React from "react";

interface BannerHeadingProps {
  title: string;
  width: string;
}

const BannerHeading: React.FC<BannerHeadingProps> = ({ title, width }) => {
  return (
    <h1
      className={`w-full lg:w-${width} text-4xl text-center lg:text-right font-bold py-4 my-7 border-b-4 border-black`}
    >
      {title}
    </h1>
  );
};
export default BannerHeading;
