import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlus,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../utils/Modal";
import CreateNewField from "./CreateNewField";
// import CreateNewField from "./CreateNewField";

interface CustomField {
  id: number;
  label: string;
  name: string;
}

const CustomFields: React.FC = () => {
  const [fields, setFields] = useState<CustomField[]>([
    { id: 1, name: "", label: "Caregiver's Name" },
    { id: 2, name: "", label: "Son's name" },
    { id: 3, name: "", label: "Daughter's name" },
  ]);
  const [hoveredFieldId, setHoveredFieldId] = useState<number | null>(null);

  const [editingFieldId, setEditingFieldId] = useState<number | null>(null);
  const [editedFieldName, setEditedFieldName] = useState("");
  const [showModal, setShowModal] = useState(false);

  const addNewField = (newFields: CustomField[]) => {
    setFields([...fields, ...newFields]);
  };

  const editField = (id: number, name: string) => {
    setEditingFieldId(id);
    setEditedFieldName(name);
  };

  const saveField = (id: number) => {
    setFields(
      fields.map((field) =>
        field.id === id ? { ...field, name: editedFieldName } : field
      )
    );
    setEditingFieldId(null); // Stop editing mode
  };

  const cancelEdit = () => {
    setEditingFieldId(null); // Cancel edit
  };

  return (
    <div className="border p-4 rounded-md">
      <h3 className="font-bold mb-2">Custom Fields</h3>
      <ul className="space-y-1">
        {fields.map((field) => (
          <li
            key={field.id}
            className="flex items-center space-x-2"
            onMouseEnter={() => setHoveredFieldId(field.id)}
            onMouseLeave={() => setHoveredFieldId(null)}
          >
            <span>{field.label}</span>

            {editingFieldId === field.id ? (
              <>
                <input
                  type="text"
                  value={editedFieldName}
                  onChange={(e) => setEditedFieldName(e.target.value)}
                  className="w-full border rounded p-1"
                />
                <button onClick={() => saveField(field.id)} className="z-50">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-green-600 z-50"
                  />
                </button>
                <button onClick={cancelEdit} className="z-50">
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-red-600 z-50"
                  />
                </button>
              </>
            ) : (
              <>
                <span>{field.name}</span>
                {hoveredFieldId === field.id && (
                  <FontAwesomeIcon
                    icon={faPen}
                    className="cursor-pointer text-gray-500 hover:text-gray-700 ml-2"
                    onClick={() => editField(field.id, field.name)}
                  />
                )}
              </>
            )}
          </li>
        ))}
      </ul>

      <div className="mt-2">
        <button onClick={() => setShowModal(true)}>
          <FontAwesomeIcon icon={faPlus} className="text-blue-600" />
        </button>
      </div>

      {/* Modal for adding new field */}
      {showModal && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <CreateNewField
            onAddField={addNewField}
            closeModal={() => setShowModal(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default CustomFields;
