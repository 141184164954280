import { useState } from "react";

interface ScrollBoxProps {
  children: React.ReactNode;
  openModal: (x: boolean) => void;
}

const ScrollBox: React.FC<ScrollBoxProps> = ({ children, openModal }) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleScroll = (event: any) => {
    const scrollPosition = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    if (scrollPosition + clientHeight >= scrollHeight - 5) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };
  return (
    <div
      className="overflow-y-auto max-h-96 p-4 border border-gray-300 rounded-lg"
      onScroll={handleScroll}
    >
      {children}
      <button
        className={`mt-4 px-4 py-2 mr-3 rounded-lg ${
          isButtonEnabled
            ? "bg-primary text-white"
            : "bg-gray-300 text-gray-600"
        }`}
        disabled={!isButtonEnabled}
        onClick={() => {
          openModal(false);
          document.body.style.overflow = "";
        }}
      >
        I Accept
      </button>
    </div>
  );
};

export default ScrollBox;
