import { useEffect, useState } from "react";
import Modal from "../../../components/utils/Modal";
import { set } from "date-fns";

interface Vendor {
  id: number;
  vendor: string;
  contact: string;
  title: string;
  email: string;
  telephone: string;
  additional: string;
}

const defaultVendorData: Vendor[] = [
  {
    id: 1,
    vendor: "Moving Company",
    contact: "Sherry Daily",
    title: "Owner",
    email: "sherry.daily@example.com",
    telephone: "555-123-4567",
    additional:
      "Last email: An hour ago, Last message: 6 hours ago, Created: An hour ago",
  },
  {
    id: 2,
    vendor: "Attorney",
    contact: "Geoff Goodman",
    title: "Owner",
    email: "geoff.goodman@example.com",
    telephone: "555-876-5432",
    additional:
      "Last email: An hour ago, Last message: 6 hours ago, Created: An hour ago",
  },
  {
    id: 3,
    vendor: "Handyman",
    contact: "Peter May",
    title: "Owner",
    email: "peter.may@example.com",
    telephone: "555-987-6543",
    additional:
      "Last email: A day ago, Last message: 2 Days, Created: A day ago",
  },
  {
    id: 4,
    vendor: "Home Care Provider",
    contact: "Raul Cruz",
    title: "Owner",
    email: "raul.cruz@example.com",
    telephone: "555-432-1597",
    additional:
      "Last email: A day ago, Last message: 2 Days, Created: A day ago",
  },
  {
    id: 5,
    vendor: "Garage Sale",
    contact: "Owner",
    title: "Owner",
    email: "",
    telephone: "",
    additional: "",
  },
  {
    id: 6,
    vendor: "Estate Sale",
    contact: "",
    title: "Owner",
    email: "",
    telephone: "",
    additional: "",
  },
  {
    id: 7,
    vendor: "Cleaning Crews",
    contact: "",
    title: "Owner",
    email: "",
    telephone: "",
    additional: "",
  },
];

function ClientTransitionSupport() {
  const [showModal, setShowModal] = useState(false);
  const [vendorData, setVendorData] = useState<Vendor[]>(defaultVendorData);
  const [vendorName, setVendorName] = useState("");
  const [vendorContact, setVendorContact] = useState("");
  const [vendorTitle, setVendorTitle] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorTelephone, setVendorTelephone] = useState("");
  const [vendorAdditional, setVendorAdditional] = useState("");
  const [vendorId, setVendorId] = useState<number>(-1);

  const [ifClickedByAddBtn, setIfClickedByAddBtn] = useState(false);

  const handleNewVendor = () => {
    setVendorId(-1);
    setVendorName("");
    setVendorContact("");
    setVendorTitle("");
    setVendorEmail("");
    setVendorTelephone("");
    setVendorAdditional("");
    setShowModal(true);
    setIfClickedByAddBtn(true);
  };

  const editVendor = (vendor: Vendor) => {
    setIfClickedByAddBtn(false);
    setShowModal(true);
    setVendorName(vendor.vendor);
    setVendorContact(vendor.contact);
    setVendorTitle(vendor.title);
    setVendorEmail(vendor.email);
    setVendorTelephone(vendor.telephone);
    setVendorAdditional(vendor.additional);
    setVendorId(vendor.id);
  };

  const handleAddOrEditVendor = () => {
    const newVendor = {
      id: vendorId,
      vendor: vendorName,
      contact: vendorContact,
      title: vendorTitle,
      email: vendorEmail,
      telephone: vendorTelephone,
      additional: vendorAdditional,
    };

    setVendorData((prevData) => {
      const updatedData = [...prevData];
      const vendorIndex = updatedData.findIndex(
        (vendor) => vendor.id === vendorId
      );

      if (vendorIndex !== -1 && vendorId !== -1) {
        updatedData[vendorIndex] = newVendor;
      } else {
        newVendor.id = prevData.length + 1;
        updatedData.push(newVendor);
      }

      return updatedData;
    });

    setShowModal(false);
    setVendorId(-1);
    setVendorName("");
    setVendorContact("");
    setVendorTitle("");
    setVendorEmail("");
    setVendorTelephone("");
    setVendorAdditional("");
    document.body.style.overflow = "";
  };

  const deleteVendor = () => {
    setVendorData((prevData) =>
      prevData.filter((vendor) => vendor.id !== vendorId)
    );
    setShowModal(false);
    setVendorId(-1);
    setVendorName("");
    setVendorContact("");
    setVendorTitle("");
    setVendorEmail("");
    setVendorTelephone("");
    setVendorAdditional("");
    document.body.style.overflow = "";
  };

  return (
    <div>
      {/* Leads Table */}
      <div className="w-full">
        <button className="btn-tertiary my-2" onClick={handleNewVendor}>
          Add a Vendor
        </button>
        <h2 className="text-2xl font-bold my-2">Vendor Management</h2>
        <table className="min-w-full bg-white border border-gray-300 rounded-lg my-2">
          <thead>
            <tr className="border-b">
              <th className="py-3 px-4 text-left font-semibold">Vendor</th>
              <th className="py-3 px-4 text-left font-semibold">Contact</th>
              <th className="py-3 px-4 text-left font-semibold">Title</th>
              <th className="py-3 px-4 text-left font-semibold">Email</th>
              <th className="py-3 px-4 text-left font-semibold">Telephone</th>
              <th className="py-3 px-4 text-left font-semibold">Additional</th>
            </tr>
          </thead>
          <tbody>
            {vendorData.map((vendor) => (
              <tr
                onClick={() => editVendor(vendor)}
                key={vendor.id}
                className="border-b hover:bg-gray-100 hover:cursor-pointer"
              >
                <td className="py-3 px-4">{vendor.vendor}</td>
                <td className="py-3 px-4">{vendor.contact}</td>
                <td className="py-3 px-4">{vendor.title}</td>
                <td className="py-3 px-4">{vendor.email}</td>
                <td className="py-3 px-4">{vendor.telephone}</td>
                <td className="py-3 px-4">{vendor.additional}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {showModal && (
          <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
            <div className="flex flex-col space-y-4">
              <h2 className="text-2xl font-bold">Add a Vendor</h2>
              <form className="flex flex-col space-y-4">
                <label className="block mb-2">
                  Vendor
                  <input
                    className="w-full mt-1 p-2 border rounded-md"
                    type="text"
                    onChange={(e) => setVendorName(e.target.value)}
                    value={vendorName}
                  />
                </label>
                <label className="block mb-2">
                  Contact
                  <input
                    className="w-full mt-1 p-2 border rounded-md"
                    type="text"
                    onChange={(e) => setVendorContact(e.target.value)}
                    value={vendorContact}
                  />
                </label>
                <label className="block mb-2">
                  Title
                  <input
                    className="w-full mt-1 p-2 border rounded-md"
                    type="text"
                    onChange={(e) => setVendorTitle(e.target.value)}
                    value={vendorTitle}
                  />
                </label>
                <label className="block mb-2">
                  Email
                  <input
                    className="w-full mt-1 p-2 border rounded-md"
                    type="email"
                    onChange={(e) => setVendorEmail(e.target.value)}
                    value={vendorEmail}
                  />
                </label>
                <label className="block mb-2">
                  Telephone
                  <input
                    className="w-full mt-1 p-2 border rounded-md"
                    type="tel"
                    onChange={(e) => setVendorTelephone(e.target.value)}
                    value={vendorTelephone}
                  />
                </label>
                <label className="block mb-2">
                  Additional
                  <textarea
                    className="w-full mt-1 p-2 border rounded-md"
                    onChange={(e) => setVendorAdditional(e.target.value)}
                    value={vendorAdditional}
                  />
                </label>
                <div>
                  <button
                    onClick={handleAddOrEditVendor}
                    type="button"
                    className="btn-tertiary"
                  >
                    {ifClickedByAddBtn ? "Add Vendor" : "Save Changes"}
                  </button>
                  {!ifClickedByAddBtn && (
                    <button onClick={deleteVendor} className="btn-danger">
                      Delete Vendor
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default ClientTransitionSupport;
