import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faCheck,
  faTimes,
  faPlus,
  faDeleteLeft,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../utils/Modal";
import CreateTag from "./CreateNewTag";

interface Tag {
  id: number;
  name: string;
}

const Tags: React.FC = () => {
  const [tags, setTags] = useState<Tag[]>([
    { id: 1, name: "Hot Seller" },
    { id: 2, name: "Warm Seller" },
    { id: 3, name: "Lease Client" },
  ]);
  const [newTag, setNewTag] = useState("");
  const [editingTagId, setEditingTagId] = useState<number | null>(null);
  const [editedTagName, setEditedTagName] = useState("");

  // For handling hover effect
  const [hoveredTagId, setHoveredTagId] = useState<number | null>(null);

  const [showModal, setShowModal] = useState(false);

  const addTag = (newTags: Tag[]) => {
    setTags([...tags, ...newTags]);
  };

  const editTag = (id: number, name: string) => {
    setEditingTagId(id);
    setEditedTagName(name);
  };

  const saveTag = (id: number) => {
    setTags(
      tags.map((tag) => (tag.id === id ? { ...tag, name: editedTagName } : tag))
    );
    setEditingTagId(null); // Stop editing mode
  };

  const cancelEdit = () => {
    setEditingTagId(null); // Stop editing mode without saving
    setEditedTagName("");
  };

  const removeTag = (id: number) => {
    setTags(tags.filter((tag) => tag.id !== id)); // Remove the tag
  };

  return (
    <div className="border p-4 rounded-md">
      <h3 className="font-bold mb-2">Tags</h3>
      <ul className="space-y-1">
        {tags.map((tag) => (
          <li
            key={tag.id}
            className="flex items-center space-x-2"
            // onMouseEnter={() => setHoveredTagId(tag.id)}
            // onMouseLeave={() => setHoveredTagId(null)}
          >
            {editingTagId === tag.id ? (
              <>
                <input
                  type="text"
                  value={editedTagName}
                  onChange={(e) => setEditedTagName(e.target.value)}
                  className="border rounded p-1 "
                />
                <FontAwesomeIcon
                  icon={faCheck}
                  onClick={() => saveTag(tag.id)}
                  className="cursor-pointer text-green-600 hover:text-green-800 ml-2"
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={cancelEdit}
                  className="cursor-pointer text-red-600 hover:text-red-800 ml-2"
                />
              </>
            ) : (
              <>
                <span className="bg-gray-200 p-1 rounded-lg">
                  {tag.name}
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="cursor-pointer hover:text-gray-600 ml-2"
                    onClick={() => removeTag(tag.id)}
                  />
                </span>
                {hoveredTagId === tag.id && (
                  <FontAwesomeIcon
                    icon={faPen}
                    className="cursor-pointer text-gray-500 hover:text-gray-700 ml-2"
                    onClick={() => editTag(tag.id, tag.name)}
                  />
                )}
              </>
            )}
          </li>
        ))}
      </ul>
      <div className="mt-2">
        <button onClick={() => setShowModal(true)}>
          <FontAwesomeIcon icon={faPlus} className="text-blue-600" />
        </button>
      </div>

      {/* Modal for adding new field */}
      {showModal && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <CreateTag onAddTag={addTag} closeModal={() => setShowModal(false)} />
        </Modal>
      )}
    </div>
  );
};

export default Tags;
