// Register.tsx
import { useNavigate } from "react-router-dom";

const Register: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    console.log("Form submitted");
    e.preventDefault();
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Become a Care Champion</h1>

      <h3 className="text-lg font-semibold mb-2">
        Please fill in the form below
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div>
          <label htmlFor="first name">First Name</label>

          <input
            type="text"
            placeholder="First name"
            className="border border-gray-400 p-2 rounded w-full"
          />
        </div>
        <div>
          <label htmlFor="last name">Last Name</label>

          <input
            type="text"
            placeholder="Last name"
            className="border border-gray-400 p-2 rounded w-full"
          />
        </div>
      </div>
      <label htmlFor="telephone">Telephone</label>

      <input
        type="tel"
        placeholder="Email"
        className="border border-gray-400 p-2 rounded w-full mb-4"
      />
      <label htmlFor="Email">Email</label>

      <input
        type="email"
        placeholder="Email"
        className="border border-gray-400 p-2 rounded w-full mb-6"
      />
      <label htmlFor="password">Password</label>

      <input
        type="password"
        placeholder="Password"
        className="border border-gray-400 p-2 rounded w-full mb-4"
      />
      <label htmlFor="password">Confirm Password</label>
      <input
        type="password"
        placeholder="Confirm Password"
        className="border border-gray-400 p-2 rounded w-full mb-6"
      />

      <button onClick={handleSubmit} className="btn-primary w-full mt-6">
        Submit
      </button>
    </div>
  );
};

export default Register;
