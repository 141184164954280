import React from "react";
import { useParams } from "react-router-dom";
import Name from "./advisorInfo/Name.tsx";
import Title from "./advisorInfo/Title.tsx";
import Summary from "./advisorInfo/Summary.tsx";
import SeniorAdvisorCard from "./SeniorAdvisorCard.tsx";

interface SeniorAdvisorProps {
  advisor: {
    photo: string;
    name: string;
    title: string;
    license: string;
    directPhone: string;
    cellPhone: string;
    email: string;
    credentials: string[];
    services: string[];
    awards: string[];
    testimonials: string[];
    yearsInBusiness: number;
    areasServed: string[];
    followMeLinks: { platform: string; url: string }[];
    summary: string;
  };
}

const SeniorAdvisor: React.FC<SeniorAdvisorProps> = ({ advisor }) => {
  return (
    <div className="flex flex-col md:flex-row p-4 bg-white shadow-md rounded-lg">
      <div className="p-3 border-2 w-full">
        <SeniorAdvisorCard advisor={advisor} />
      </div>
      {/* summary of advisor */}
      <div className="p-4">
        <Name name={advisor.name} />
        <Title title={advisor.title} />
        <Summary summary={advisor.summary} />
      </div>
    </div>
  );
};

export default SeniorAdvisor;
