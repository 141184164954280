import { useState } from "react";

const SeniorAdvisorTermsConditions = () => {
  return (
    <>
      <h2 className="text-xl font-semibold mb-4">
        Terms and Conditions for Bluejay
      </h2>
      <ol className="list-decimal pl-10">
        <li className="mb-2">
          <strong>Introduction:</strong> Welcome to Bluejay. By accessing our
          platform and using our services, you agree to comply with these Terms
          and Conditions, our Privacy Policy, and any relevant laws and
          regulations. These terms apply to all users who access or use our
          services in California.
        </li>

        <li className="mb-2">
          <strong>Services Provided:</strong> Bluejay offers a digital platform
          designed specifically for senior advisors in the real estate industry
          to connect with clients and senior communities. Services include
          profile creation, access to community databases, seller leads,
          communication tools, and other related services.
        </li>

        <li className="mb-2">
          <strong>User Responsibilities:</strong> Users must provide true,
          accurate, current, and complete information about themselves as
          prompted by our service's registration forms. Users agree to promptly
          update their personal information to keep it true, accurate, current,
          and complete.
        </li>

        <li className="mb-2">
          <strong>Prohibited Conduct:</strong> You are expressly prohibited
          from:
        </li>
        <ul className="list-disc pl-5 mb-2">
          <li>Using the service for any unlawful purposes;</li>
          <li>
            Engaging in any conduct that would constitute a criminal offense,
            give rise to civil liability, or otherwise violate any city, state,
            national, or international law or regulation. This includes adhering
            to ethical guidelines set forth by the National Association of
            Realtors and the California Department of Real Estate.
          </li>
        </ul>

        <li className="mb-2">
          <strong>Intellectual Property Rights:</strong> All content on Bluejay,
          including designs, text, graphics, pictures, and information, is the
          exclusive property of Bluejay or its licensors and is protected by
          U.S. and international copyright and intellectual property laws.
        </li>

        <li className="mb-2">
          <strong>Account Termination:</strong> Bluejay reserves the right to
          terminate your account or restrict access to the service for any
          reason, particularly upon any breach of these Terms and Conditions.
        </li>

        <li className="mb-2">
          <strong>Governing Law:</strong> These Terms shall be governed and
          construed by California, United States laws, without regard to its
          conflict of law provisions.
        </li>

        <li className="mb-2">
          <strong>Dispute Resolution:</strong> All disputes related to these
          Terms will be resolved through final and binding arbitration rather
          than in court, except that you may assert claims in small claims court
          if your claims qualify.
        </li>

        <li className="mb-2">
          <strong>Amendments to These Terms:</strong> We reserve the right to
          modify these terms at any time. Your continued platform use after such
          changes constitutes your acceptance of the new Terms.
        </li>

        <li className="mb-2">
          <strong>Contact Information:</strong> If you have any questions about
          these Terms, please contact us at glenn@shelhamergroup.com.
        </li>
      </ol>

      <h2 className="text-xl font-semibold mb-4 mt-8">
        Privacy Policy for Bluejay
      </h2>

      <ol className="list-decimal pl-10">
        <li className="mb-2">
          <strong>Information Collection, Use, and Sharing:</strong> We collect
          personally identifiable information (name, email, etc.) you provide us
          directly on our platform. We are the sole owners of this information
          and will not sell or rent this information to anyone.
        </li>

        <li className="mb-2">
          <strong>CCPA Compliance:</strong> Under the California Consumer
          Privacy Act (CCPA), California residents have specific rights
          regarding the personal information we collect. These rights include
          requesting access to their data, requesting deletion, and requesting
          information about data-sharing practices.
        </li>

        <li className="mb-2">
          <strong>Security:</strong> We take precautions to protect your online
          and offline information. Only employees who need the information to
          perform a specific job are granted access to personally identifiable
          information.
        </li>

        <li className="mb-2">
          <strong>Cookies:</strong> We use "cookies" on our site to enhance user
          experience and analyze site usage. You can refuse cookies by setting
          your browser to decline them.
        </li>

        <li className="mb-2">
          <strong>Third Parties:</strong> We may use third-party services to
          process or store your data. These third parties comply with the same
          commitments we make under this policy.
        </li>

        <li className="mb-2">
          <strong>Changes to This Privacy Policy:</strong> We may update our
          Privacy Policy periodically. We will notify you of any changes by
          posting the new Privacy Policy on our site.
        </li>

        <li className="mb-2">
          <strong>Contact Us:</strong> If you have any questions or concerns
          about this Privacy Policy, please use the provided contact information
          to reach out.
        </li>
      </ol>
    </>
  );
};

export default SeniorAdvisorTermsConditions;
