import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SearchBar: React.FC = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [categoryQuery, setCategoryQuery] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleCategoryInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCategoryQuery(e.target.value);
  };

  const handleSearch = () => {
    setCategoryQuery("");
    setQuery("");
    navigate("/search-results");
  };

  return (
    <div className="hidden md:flex md:flex-col items-center w-full mt-10 ">
      <h2 className="text-4xl text-primary mb-8 font-semibold search-bar-heading tracking-tight">
        Find Senior Living Near You
      </h2>

      <div className="flex items-center w-full max-w-xl bg-white shadow-md rounded-full p-4 space-x-4">
        {/* Category Input */}
        <input
          className="flex-1 bg-gray-100 text-gray-700 border border-transparent rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-primary transition-all"
          type="text"
          placeholder="Memory Care, Assisted Living, etc."
          value={categoryQuery}
          onChange={handleCategoryInputChange}
        />

        {/* Vertical Divider */}
        <div className="border-l-2 border-gray-300 h-8"></div>

        {/* Location Input */}
        <input
          className="flex-1 bg-gray-100 text-gray-700 border border-transparent rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-primary transition-all"
          type="text"
          placeholder="Location"
          value={query}
          onChange={handleInputChange}
        />

        {/* Search Button */}
        <button
          className="bg-primary hover:bg-secondary text-white py-2 px-6 rounded-full transition-colors duration-300"
          onClick={handleSearch}
          aria-label="Search"
        >
          <svg
            width="24"
            height="24"
            fill="currentColor"
            className="text-white"
          >
            <path d="m21.853 20.355-3.444-3.443a9.428 9.428 0 1 0-16.761-6.171 9.428 9.428 0 0 0 15.348 7.586l3.443 3.442a1 1 0 1 0 1.414-1.414ZM5.82 16.245a7.429 7.429 0 1 1 5.253 2.175 7.38 7.38 0 0 1-5.253-2.176Z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
