import Modal from "../../../components/utils/Modal";
import ScrollBox from "../../../components/utils/scrollbox/scrollBox";
import CommunityChampTermsConditions from "../../../components/admin/Community/communityChampTermsConditions";
import { useState } from "react";

const Register: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Step 1: License Verification</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <input
          type="text"
          placeholder="First name"
          className="border border-gray-400 p-2 rounded w-full"
        />
        <input
          type="text"
          placeholder="Last name"
          className="border border-gray-400 p-2 rounded w-full"
        />
      </div>

      <input
        type="tel"
        placeholder="Phone number"
        className="border border-gray-400 p-2 rounded w-full mb-4"
      />

      <input
        type="text"
        placeholder="Title"
        className="border border-gray-400 p-2 rounded w-full mb-4"
      />

      <input
        type="email"
        placeholder="Email"
        className="border border-gray-400 p-2 rounded w-full mb-6"
      />

      <h2 className="text-lg font-bold mb-2">Community Information</h2>

      <div className="mb-4">
        <label className="block text-gray-700 mb-1">Community Name:</label>
        <input
          type="text"
          className="border border-gray-400 p-2 rounded w-full"
        />
      </div>

      <p className="text-sm text-gray-500 mb-4">
        Department of Social Services (CDSS). The Community Care Licensing
        Division (CCLD)
      </p>

      <div className="mb-4">
        <label className="block text-gray-700 mb-1">CCLD#</label>
        <input
          type="text"
          placeholder="9 digits"
          className="border border-gray-400 p-2 rounded w-full"
        />
      </div>
      <button
        type="submit"
        onClick={() => setModalOpen(true)}
        className="w-full px-4 py-2 font-bold text-white bg-primary rounded-md hover:bg-secondary focus:outline-none focus:ring focus:ring-indigo-200"
      >
        Register
      </button>
      {modalOpen && (
        <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
          <ScrollBox openModal={setModalOpen}>
            <CommunityChampTermsConditions />
          </ScrollBox>
        </Modal>
      )}
    </div>
  );
};

export default Register;
