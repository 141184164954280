import BannerHeading from "../../components/utils/BannerHeading.tsx";
import BannerImg from "../../components/utils/BannerImg.tsx";
import bannerImg from "../../assets/banners/Claim-community.png";
import img from "../../assets/20.jpg";
import { Link } from "react-router-dom";

const ClaimCommunity = () => {
  return (
    <div className="w-full overflow-hidden">
      <BannerHeading
        title="Introducing a Game-Changer for Your Senior Living Communities"
        width="2/3"
      />
      <BannerImg imgUrl={bannerImg} />
      <div className="container  flex flex-col mt-7">
        <div className="flex w-1/2 pl-14 pb-2 items-center justify-end border-b-4 border-black">
          {/* <Link
            to="/admin/community-champion/register"
            aria-label="claim your community"
            className="btn-tertiary !m-0"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            
          </Link> */}
          <h3 className="text-right text-lg font-semibold">
            Claim Your Community on BlueJay Today!
          </h3>
        </div>
        <div className="flex my-7 ">
          <div className="w-1/2 container mx-auto p-4 md:p-10">
            <h3 className="text-3xl font-bold mb-7">Enjoy Free Referrals</h3>
            <p>
              Navigating the complexities of paying referral fees and connecting
              with prospective residents can be challenging. That's why we are
              thrilled to introduce our innovative platform, designed to
              transform how you engage with senior advisors and potential
              residents.
            </p>
          </div>
          <div className="w-1/2 pb-7 border-b-4 border-black">
            <img
              className="w-2/3 mx-auto"
              src={img}
              alt="a woman looking down on the road"
            />
          </div>
        </div>

        <div className="container space-y-8 p-6 rounded-lg">
          <section>
            <h3 className="text-2xl font-bold text-gray-800">
              Streamlined Sign-Up Process
            </h3>
            <h4 className="text-xl  font-semibold text-gray-700 mt-2">
              Register Your Community
            </h4>
            <p className="text-gray-600 mt-2">
              Simply sign up and upload details about your senior living
              community. Our platform is engineered to compete with local
              advisors and major industry players like A Place for Mom,
              Senioradvisor.com, and caring.com, ensuring you stand out.
            </p>
          </section>

          <section>
            <h3 className="text-2xl font-bold text-gray-800">
              Enhanced Connections
            </h3>
            <h4 className="text-xl font-semibold text-gray-700 mt-2">
              Personalized Support
            </h4>
            <p className="text-gray-600 mt-2">
              Our dedicated senior advisors will guide you every step of the
              way. They'll introduce you to potential residents and their
              families, managing objections proactively to ensure a seamless
              experience.
            </p>
          </section>

          <section>
            <h3 className="text-2xl font-bold text-gray-800">
              Pre-Tour Insights
            </h3>
            <h4 className="text-xl font-semibold text-gray-700 mt-2">
              Tailored Visits
            </h4>
            <p className="text-gray-600 mt-2">
              Gain insights into a resident’s specific needs before they even
              tour your community. This preparation allows you to customize
              their visit, addressing their concerns effectively and
              highlighting how your community can meet their needs.
            </p>
          </section>

          <section>
            <h3 className="text-2xl font-bold text-gray-800">
              Fee-Free Advantage
            </h3>
            <h4 className="text-xl font-semibold text-gray-700 mt-2">
              Zero Costs, Pure Benefits
            </h4>
            <p className="text-gray-600 mt-2">
              Say goodbye to referral and placement fees. Our commitment to
              supporting your community comes at no cost to you, allowing you to
              focus on what’s most important—providing exceptional care and
              creating the perfect environment for your residents.
            </p>
          </section>

          <section>
            <h3 className="text-2xl font-bold text-gray-800">
              Join Our Revolution
            </h3>
            <p className="text-gray-600 mt-2">
              Together, let's redefine the senior living experience. Our
              platform makes connections not only easier and more efficient but
              also completely free of charges. Join us today and be part of the
              change.
            </p>
          </section>
        </div>

        <div className="container p-6  rounded-lg">
          <h3 className="text-3xl font-bold text-gray-800">
            Ready to Claim Your Community?
          </h3>
          <p className="text-gray-600 mt-2">
            Take the first step towards transforming your senior living
            community. Register today and enjoy the benefits of our innovative
            platform.
          </p>
        </div>
        <Link
          to="/admin/community-champion/register"
          className="btn-tertiary mb-4"
          aria-label=" claim your community"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          Register
        </Link>
      </div>
    </div>
  );
};

export default ClaimCommunity;
