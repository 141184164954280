// SingleAdvisor.js

interface SingleAdvisorProps {
  advisor: {
    name: string;
    imgSrc: string;
    licenseNumber: string;
  };
  handlePrevious: () => void;
  handleNext: () => void;
}

const SingleAdvisor: React.FC<SingleAdvisorProps> = ({
  advisor,
  handlePrevious,
  handleNext,
}) => {
  return (
    <div className="flex flex-col justify-center mb-6">
      <img
        src={advisor.imgSrc}
        alt={advisor.name}
        className="rounded-full w-32 h-32 object-contain mb-3"
      />
      <p className="font-semibold ">{advisor.name} | SRES Senior Advisor</p>
      <p className="text-sm text-gray-600">{advisor.licenseNumber}</p>

      {/* Buttons to navigate advisors */}
      <div className="flex space-x-4 mt-4">
        <button
          onClick={handlePrevious}
          className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400"
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SingleAdvisor;
