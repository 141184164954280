import React, { useState } from "react";
import Modal from "../../../../utils/Modal";
import CreateTask from "./CreateTask";
import TaskCard from "./TaskCard";
import CompletedTasks from "./CompletedTasks";
import Toggler from "./Toggler";

type Task = {
  name: string;
  type:
    | "follow_up"
    | "call"
    | "listing_appointment"
    | "email"
    | "text"
    | "tour"
    | "welfare_check";
  dueDate: string;
  dueTime: string;
};

const TaskTracker: React.FC = () => {
  const [openSection, setOpenSection] = useState<boolean>(false);

  const [showTaskModal, setShowTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [taskData, setTaskData] = useState<Task[]>([
    {
      name: "Follow Up with Client",
      type: "follow_up",
      dueDate: "2024-10-27",
      dueTime: "10:00",
    },
    {
      name: "Tour the Facility",
      type: "tour",
      dueDate: "2024-10-28",
      dueTime: "14:00",
    },
  ]);

  const toggleSection = () => {
    setOpenSection((prev) => !prev);
  };

  const handleAddTask = (task: Task) => {
    if (selectedTask) {
      setTaskData((prevData) =>
        prevData.map((t) => (t === selectedTask ? task : t))
      );
    } else {
      setTaskData((prevData) => [task, ...prevData]);
    }
    setShowTaskModal(false);
    setSelectedTask(null);
  };

  const handleEditTask = (task: Task) => {
    // Add functionality to edit a task
    setSelectedTask(task);
    setShowTaskModal(true);
  };

  const [completedTasks, setCompletedTasks] = useState<Task[]>([]);

  // Handle task completion
  const handleTaskComplete = (taskName: string) => {
    // Move the task to completed list
    const taskToComplete = taskData.find((task) => task.name === taskName);
    if (taskToComplete) {
      setTaskData(taskData.filter((task) => task.name !== taskName)); // Remove from active tasks
      setCompletedTasks([...completedTasks, taskToComplete]); // Add to completed tasks
    }
  };

  return (
    <>
      {/* Task Tracker Section */}
      <div className="mb-4">
        <Toggler
          openSection={openSection}
          setOpenSection={setOpenSection}
          toggleSection={toggleSection}
          setShowModal={setShowTaskModal}
          title="Task Tracker"
        />
        {openSection && (
          <div className="mt-2 p-2 ">
            {/* Add content here for Task Tracker */}
            {taskData.map((task, index) => (
              <div key={index} className="">
                <TaskCard
                  key={task.name}
                  onTaskComplete={handleTaskComplete}
                  task={task}
                  onClick={() => handleEditTask(task)}
                />
              </div>
            ))}
            <h2 className="text-lg font-bold mt-8 mb-4">Completed Tasks</h2>
            {completedTasks.length === 0 ? (
              <p className="text-gray-500">No completed tasks</p>
            ) : (
              <CompletedTasks completedTasks={completedTasks} />
            )}
          </div>
        )}
      </div>

      {/* Modals */}
      {showTaskModal && (
        <Modal isOpen={showTaskModal} onClose={() => setShowTaskModal(false)}>
          <CreateTask onCreateTask={handleAddTask} initialData={selectedTask} />
        </Modal>
      )}
    </>
  );
};

export default TaskTracker;
