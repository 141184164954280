import React from "react";

const Stage: React.FC = () => {
  return (
    <div className="border p-4 rounded-md">
      <h3 className="font-bold mb-2">Stage Lead</h3>
      <select className="input-field">
        <option>Hot Seller (1-3 months)</option>
        <option>Warm Seller (6-12 months)</option>
        <option>Cold Seller (1-3 years)</option>
        <option>Lease Client</option>
        <option>Aging in Place</option>
        <option>Legacy Planning</option>
      </select>
    </div>
  );
};

export default Stage;
