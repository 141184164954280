import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faHeart,
  faCog,
  faCommentAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons"; // Importing FontAwesome icons

import CareChampionRegisterPage from "../../pages/Admin/AdminCareChampion/Register"; // Import CareChampionRegister from "../careChampion/Register";
import CareChampionRegister from "../careChampion/Register";
import Modal from "../utils/Modal";

const SecondMenu: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // Modal state
  const [isSelected, setIsSelected] = useState<number | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);

  const menuItems = [
    {
      to: "/admin/care-champion/login",
      label: "Login",
      index: 0,
      dropdown: [
        { to: "/about-me", label: "About Me", icon: faUser },
        { to: "/favorites", label: "Favorites", icon: faHeart },
        { to: "/account-settings", label: "Account Settings", icon: faCog },
        { to: "/messages", label: "Messages", icon: faCommentAlt },
        { to: "/logout", label: "Logout", icon: faSignOutAlt },
      ],
    },
    {
      to: "#",
      label: "Sign Up",
      index: 1,
    },
  ];

  const location = useLocation();

  const handleLinkClick = (index: number) => {
    setIsSelected(index);
  };

  const handleMouseEnter = (index: number) => {
    setDropdownOpen(index);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(null);
  };

  // modal control
  const handleSignUpClick = () => {
    setIsModalOpen(true); // Open modal on sign-up click
  };

  return (
    <div className="self-center">
      <div className="mx-auto">
        {menuItems.map((item) => (
          <div
            key={item.index}
            onMouseEnter={() => handleMouseEnter(item.index)}
            onMouseLeave={handleMouseLeave}
            className="relative inline-block"
          >
            <Link
              to={item.to}
              onClick={() =>
                item.index === 1
                  ? handleSignUpClick()
                  : handleLinkClick(item.index)
              } // Trigger modal for Sign Up
              className={`block bg-primary p-3 border-1 border-primary rounded mt-4 md:inline-block md:mt-0 text-secondary mr-4 hover:bg-tertiary-500 hover:text-accent-100 
                ${
                  isSelected === item.index || location.pathname === item.to
                    ? "text-primary"
                    : "text-accent"
                }`}
              aria-label={item.label}
            >
              {item.label}
            </Link>

            {/* Dropdown Menu */}
            {item.dropdown && dropdownOpen === item.index && (
              <div
                className="absolute left-0 mt-0 w-48 bg-white border rounded shadow-lg z-10"
                onMouseEnter={() => handleMouseEnter(item.index)}
                onMouseLeave={handleMouseLeave}
              >
                {item.dropdown.map((subItem, subIndex) => (
                  <Link
                    key={subIndex}
                    to={subItem.to}
                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                  >
                    {/* Icon on the left */}
                    <FontAwesomeIcon
                      icon={subItem.icon}
                      className="w-5 h-5 text-gray-600 mr-2"
                    />
                    {subItem.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CareChampionRegister />
        {/* <CareChampionRegisterPage /> */}
      </Modal>
    </div>
  );
};

export default SecondMenu;
