import React from "react";
import Photo from "./advisorInfo/Photo";
import Name from "./advisorInfo/Name";
import Title from "./advisorInfo/Title";
import LicenseNumber from "./advisorInfo/LicenseNumber";
import DirectPhone from "./advisorInfo/DirectPhone";
import CellPhone from "./advisorInfo/CellPhone";
import EmailButton from "./advisorInfo/EmailButton";
import CredentialsList from "./advisorInfo/CredentialsList";
import ServicesList from "./advisorInfo/ServicesList";
import AwardsRecognitions from "./advisorInfo/AwardsRecognitions";
import ClientTestimonials from "./advisorInfo/ClientTestimonials";
import YearsInBusiness from "./advisorInfo/YearsInBusiness";
import AreasServed from "./advisorInfo/AreasServed";
import FollowMeLinks from "./advisorInfo/FollowMeLinks";

interface SeniorAdvisorCardProps {
  advisor: {
    photo?: string;
    name?: string;
    title?: string;
    license?: string;
    directPhone?: string;
    cellPhone?: string;
    email?: string;
    credentials?: string[];
    services?: string[];
    awards?: string[];
    testimonials?: string[];
    yearsInBusiness?: number;
    areasServed?: string[];
    followMeLinks?: { platform: string; url: string }[];
  };
}

const SeniorAdvisorCard: React.FC<SeniorAdvisorCardProps> = ({ advisor }) => {
  return (
    <div className="advisor-card border p-4 rounded-lg">
      {/* Conditionally render each component if the data is provided */}
      {advisor.photo && <Photo src={advisor.photo} />}
      {advisor.name && <Name name={advisor.name} />}
      {(advisor.title || advisor.license) && (
        <div className="flex flex-col align-middle md:flex-row">
          {advisor.title && <Title title={advisor.title} />}
          {advisor.license && (
            <span className="hidden md:inline font-bold mb-2 ml-2 mr-2">
              {" | "}
            </span>
          )}
          {advisor.license && <LicenseNumber license={advisor.license} />}
        </div>
      )}
      {advisor.directPhone && <DirectPhone phone={advisor.directPhone} />}
      {advisor.cellPhone && <CellPhone phone={advisor.cellPhone} />}
      {advisor.email && <EmailButton email={advisor.email} />}
      {advisor.credentials && advisor.credentials.length > 0 && (
        <CredentialsList credentials={advisor.credentials} />
      )}
      {advisor.services && advisor.services.length > 0 && (
        <ServicesList services={advisor.services} />
      )}
      {advisor.awards && advisor.awards.length > 0 && (
        <AwardsRecognitions awards={advisor.awards} />
      )}
      {advisor.testimonials && advisor.testimonials.length > 0 && (
        <ClientTestimonials testimonials={advisor.testimonials} />
      )}
      {advisor.yearsInBusiness !== undefined && (
        <YearsInBusiness years={advisor.yearsInBusiness} />
      )}
      {advisor.areasServed && advisor.areasServed.length > 0 && (
        <AreasServed areas={advisor.areasServed} />
      )}
      {advisor.followMeLinks && advisor.followMeLinks.length > 0 && (
        <FollowMeLinks links={advisor.followMeLinks} />
      )}
    </div>
  );
};

export default SeniorAdvisorCard;
