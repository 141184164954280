import { useState } from "react";

const CareChampTermsConditions = () => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleScroll = (event: any) => {
    const scrollPosition = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const clientHeight = event.target.clientHeight;

    if (scrollPosition + clientHeight >= scrollHeight) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  return (
    <>
      <h2 className="text-xl font-semibold mb-4">Terms and Conditions</h2>
      <h3 className="text-lg font-semibold mb-2">Care Champions</h3>
      <p className="mb-2">
        1. Introduction Welcome to Bluejay®, the platform dedicated to helping
        older adults and their Care Champions find the perfect senior living
        community. By using our website, you agree to these Terms and
        Conditions. Please read them carefully.
      </p>
      <p className="mb-2">2. Definitions</p>
      <ul className="list-disc pl-5 mb-2">
        <li>
          "Care Champions" refers to users who assist older adults in finding
          suitable senior living options, whether they are family members,
          caregivers, or friends. They may also be the individuals themselves.
        </li>
        <li>
          "Senior Advisors" are licensed real estate agents who specialize in
          senior living options and provide expert guidance through Bluejay®.
        </li>
        <li>
          "Communities" refers to the various senior living communities listed
          on Bluejay®.
        </li>
      </ul>
      <p className="mb-2">
        3. Account Registration and Responsibilities ● Users must register to
        access certain site features. You agree to provide accurate and complete
        information and to keep it up-to-date. ● You are responsible for
        maintaining the confidentiality of your account password and all
        activities under your account.
      </p>
      <p className="mb-2">4. Use of the Platform</p>
      <ul className="list-disc pl-5 mb-2">
        <li>
          Bluejay® provides a platform for users to connect with Senior Advisors
          and Communities. We do not own or operate senior living communities or
          provide care services.
        </li>
        <li>
          Users can browse community profiles, request tours, and connect with
          Senior Advisors.
        </li>
      </ul>
      <p className="mb-2">5. User Conduct</p>
      <ul className="list-disc pl-5 mb-2">
        <li>
          Users agree not to use the platform for any unlawful purpose or in any
          way that interrupts, damages, or impairs the service.
        </li>
        <li>
          Posting accurate information, respecting the rights of others, and
          using this platform for its intended purposes are mandatory practices.
        </li>
      </ul>
      <p className="mb-2">6. Intellectual Property</p>
      <ul className="list-disc pl-5 mb-2">
        <li>
          All content on Bluejay®, including text, graphics, logos, and
          software, is the property of Bluejay® or its content suppliers and is
          protected by U.S. and international intellectual property laws.
        </li>
      </ul>
      <p className="mb-2">7. Privacy</p>
      <ul className="list-disc pl-5 mb-2">
        <li>
          Your privacy is important to us. Our Privacy Policy details how we
          collect, use, and protect your information. By using Bluejay®, you
          agree to the terms of our Privacy Policy.
        </li>
      </ul>
      <p className="mb-2">8. Disclaimers and Limitation of Liability</p>
      <ul className="list-disc pl-5 mb-2">
        <li>
          Bluejay® is provided "as is." While we strive to provide a helpful and
          error-free platform, we do not guarantee the accuracy or completeness
          of the information provided.
        </li>
        <li>
          We are not liable for any decisions based on the information provided
          on Bluejay®.
        </li>
      </ul>
      <p className="mb-2">9. Changes to Terms</p>
      <ul className="list-disc pl-5 mb-2">
        <li>
          We may update these Terms and Conditions from time to time. We will
          notify you of any changes by posting the new Terms and Conditions on
          this page.
        </li>
      </ul>
      <p className="mb-2">10. Contact Us</p>
      <ul className="list-disc pl-5 mb-2">
        <li>
          If you have any questions about these Terms, please contact us through
          the website.
        </li>
      </ul>
    </>
  );
};

export default CareChampTermsConditions;
