// AreaOfService.tsx
import React from "react";

const areas = {
  "Central Los Angeles": [
    "Downtown LA",
    "Silver Lake",
    "Chinatown",
    "Hollywood",
    "Los Feliz",
    "Mid-Wilshire",
    "Hancock Park",
    "Koreatown",
    "Pico-Union",
    "Echo Park",
    "Boyle Heights",
  ],
  "East Los Angeles": [
    "El Sereno",
    "San Gabriel",
    "Chinatown",
    "Lincoln Heights",
    "Rosemead",
    "Montebello",
    "Monterey Park",
    "Alhambra",
  ],
  Westside: [
    "Santa Monica",
    "Culver City",
    "West Hollywood",
    "Venice",
    "Marina del Rey",
    "Westwood",
    "Beverly Hills",
    "Brentwood",
    "Bel-Air",
    "Pacific Palisades",
    "Century City",
  ],
  "San Fernando Valley": [
    "Sherman Oaks",
    "Studio City",
    "North Hollywood",
    "Van Nuys",
    "Burbank",
    "Encino",
    "Woodland Hills",
    "Canoga Park",
    "Northridge",
    "Granada Hills",
    "Reseda",
    "Tarzana",
    "Panorama City",
    "Sylmar",
    "Sun Valley",
    "Mission Hills",
    "Chatsworth",
    "Pacoima",
    "San Fernando (city)",
  ],
  "South Los Angeles": [
    "Inglewood",
    "Crenshaw",
    "Baldwin Hills",
    "Leimert Park",
    "South Central LA",
    "Watts",
    "Florence",
    "Hyde Park",
    "Exposition Park",
    "West Athens",
  ],
  "Southeast Los Angeles": [
    "Huntington Park",
    "Bell",
    "South Gate",
    "Cudahy",
    "Lynwood",
    "Downey",
    "Norwalk",
    "Paramount",
    "Bellflower",
    "Lakewood",
  ],
  "South Bay": [
    "Torrance",
    "Redondo Beach",
    "Hermosa Beach",
    "Manhattan Beach",
    "El Segundo",
    "Gardena",
    "Carson",
    "Hawthorne",
    "Lawndale",
    "Lomita",
    "Palos Verdes Estates",
    "Rancho Palos Verdes",
    "Rolling Hills Estates",
  ],
  "San Gabriel Valley": [
    "Pasadena",
    "South Pasadena",
    "Arcadia",
    "San Marino",
    "Temple City",
    "Monrovia",
    "Duarte",
    "La Cañada Flintridge",
    "Glendora",
    "Azusa",
    "West Covina",
    "Covina",
    "El Monte",
    "Baldwin Park",
    "La Puente",
  ],
  "Gateway Cities": [
    "Long Beach",
    "Lakewood",
    "Signal Hill",
    "Cerritos",
    "Bell Gardens",
    "Artesia",
    "La Mirada",
    "Whittier",
    "Pico Rivera",
    "Commerce",
    "Santa Fe Springs",
    "Downey",
  ],
  "Santa Clarita Valley": [
    "Santa Clarita (city)",
    "Valencia",
    "Saugus",
    "Canyon Country",
    "Newhall",
    "Stevenson Ranch",
    "Castaic",
  ],
  "Antelope Valley": [
    "Palmdale",
    "Lancaster",
    "Quartz Hill",
    "Acton",
    "Littlerock",
    "Lake Los Angeles",
  ],
  "Other Cities/Unincorporated Areas in LA County": [
    "Malibu",
    "Calabasas",
    "Agoura Hills",
    "La Verne",
    "Claremont",
    "Pomona",
    "San Dimas",
    "Sierra Madre",
  ],
};

const AreasOfService: React.FC = () => {
  return (
    <div className="py-6">
      <h2 className="text-2xl font-bold mb-4">Step 3: Area of Service</h2>
      <p className="text-gray-700 mb-2">
        <strong>Areas of Service:</strong> Select the geographical areas where
        you operate. This ensures your services appear to clients in locales you
        serve, optimizing your impact and efficiency.
      </p>
      <p className="text-gray-700 mb-6">Service Regions</p>

      {Object.entries(areas).map(([region, locations]) => (
        <div key={region} className="mb-8">
          <h3 className=" font-semibold mb-2">{region}</h3>
          <div className="border border-gray-300 p-4 rounded-lg">
            <div className="grid grid-cols-3 gap-4">
              {locations.map((location) => (
                <label key={location} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    className="form-checkbox h-[15px] w-[15px] text-blue-600"
                  />
                  <span className="text-gray-800">{location}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AreasOfService;
