import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbTack,
  faEnvelope,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import CreateNote from "../Helper/Communication/CreateNote";
import SendEmail from "../Helper/Communication/SendEmail";
import DirectMsg from "../Helper/Communication/DirectMsg";

interface MessageControllerProps {
  forCreateNote: (note: string) => void;
}

const MessageController: React.FC<MessageControllerProps> = ({
  forCreateNote,
}) => {
  const [activeTab, setActiveTab] = useState<string>("createNote");

  const renderTabContent = () => {
    switch (activeTab) {
      case "createNote":
        return <CreateNote onAddNote={forCreateNote} />;
      case "sendEmail":
        return <SendEmail />;
      case "directMsg":
        return <DirectMsg />;
      default:
        return <CreateNote onAddNote={forCreateNote} />;
    }
  };

  return (
    <div className="w-full min-h-52">
      {/* Tab Navigation */}
      <div className="space-x-4 mb-4">
        <button
          className={`btn text-gray-400 hover:text-black ${
            activeTab === "createNote" ? "!text-black font-bold " : ""
          }`}
          onClick={() => setActiveTab("createNote")}
        >
          <FontAwesomeIcon className="mx-2" icon={faThumbTack} />
          Create Note
        </button>

        <button
          className={`btn text-gray-400 hover:text-black ${
            activeTab === "sendEmail" ? "!text-black " : ""
          }`}
          onClick={() => setActiveTab("sendEmail")}
        >
          <FontAwesomeIcon className="mx-2" icon={faEnvelope} />
          Send Email
        </button>

        <button
          className={`btn text-gray-400 hover:text-black ${
            activeTab === "directMsg" ? "!text-black " : ""
          }`}
          onClick={() => setActiveTab("directMsg")}
        >
          <FontAwesomeIcon className="mx-2" icon={faPaperPlane} />
          Direct Msg
        </button>
      </div>

      {/* Render Tab Content */}
      {renderTabContent()}
    </div>
  );
};

export default MessageController;
