import React from "react";
import BannerImg from "../components/utils/BannerImg";
import bannerImg from "../assets/banners/How it works.png";
import img1 from "../assets/howItWorksImg.jpg";
// import FAQ from "../components/faq/Faq";
import FAQ from "../components/faq/FaqNoAccordion";

const HowItWorks: React.FC = () => {
  return (
    <div className="mb-10">
      <div className="w-full overflow-hidden">
        <h1 className="w-1/2 text-4xl text-right font-bold py-4 my-7 pl-7 border-b-4 border-black">
          Disclosure and Release of Liability
        </h1>

        <div className="container">
          <h3 className="text-2xl font-semibold my-4">
            Disclosure and Release of Liability
          </h3>
          <p className="text-lg mt-3">
            1. Agreement to Terms: By using the Blue Jay website, you agree to
            the terms outlined in this Disclosure and Release of Liability.
            Please read them carefully before using our services.
          </p>
          <p className="text-lg mt-3">
            2. Nature of Service: Blue Jay provides a platform that facilitates
            connections between users, senior communities, and real estate
            professionals. We offer this service free of charge to users and
            communities. Our platform lists senior advisors and real estate
            professionals who are independent contractors; they are not
            employees of Blue Jay.
          </p>
          <p className="text-lg mt-3">
            3. Role of Real Estate Professionals: Real estate professionals,
            including agents and brokers listed on our website, are active,
            licensed agents in the State of California. They are regulated by
            the California Department of Real Estate (DRE) and are obligated to
            abide by all applicable laws, regulations, and ethical standards
            governing real estate professionals in California.
          </p>
          <p className="text-lg mt-3">
            4. Independent Contractors: Senior advisors and real estate
            professionals engaged through Blue Jay operate as independent
            contractors. Blue Jay does not control or direct their professional
            activities. Any contracts or agreements formed through interactions
            on our website are solely between the user and the respective
            independent contractor.
          </p>
          <p className="text-lg mt-3">
            5. Release of Liability: By using the Blue Jay platform, you agree
            to release Blue Jay, its affiliates, officers, agents, and employees
            from any claims, demands, and damages arising from or in any way
            connected to your use of the information provided by independent
            contractors on the site.
          </p>
          <p className="text-lg mt-3">
            6. No Professional Advice: The information provided on the Blue Jay
            website is designed to help you gather information from real estate
            professionals but is not intended as professional advice. We
            recommend that you seek independent advice from a qualified
            professional before making any decisions.
          </p>
          <p className="text-lg mt-3">
            7. Acknowledgment: By using this platform, you acknowledge that you
            have read this disclosure and release of liability and that you
            understand its terms.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
