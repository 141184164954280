import LeadTrackerTable from "../../../components/admin/InfoCard/Components/Lead/LeadTrackerTable";

function Leads() {
  return (
    <div>
      <LeadTrackerTable />
    </div>
  );
}

export default Leads;
