import React, { useEffect, useState } from "react";
import Editor from "./Editor.tsx";
import axiosInstance from "../../../../components/api/axiosInstance.tsx";
import axios from "axios";

interface BlogPost {
  id: number;
  title: string;
  body: string;
  publishedOn: string;
  visible: boolean;
  author: string;
}

const BlogPostForm: React.FC = () => {
  const [formData, setFormData] = useState<BlogPost>({
    id: 1,
    title: "",
    body: "",
    publishedOn: new Date().toISOString(),
    visible: true,
    author: "",
  });

  useEffect(() => {
    // Fetch blog post data (e.g., for editing)
    axiosInstance.get("/blogs/1").then((response) => {
      console.log(response, "from blogs");
      setFormData(response.data);
    });
  }, []);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    // const { name, value } = e.target;
    const { name, value, type, checked } = e.target as HTMLInputElement;
    setFormData((prevData) => ({
      ...prevData,
      //   [name]: name === "visible" ? e.target.checked : value,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handlePublish = () => {
    console.log("Publishing blog post:", formData);
    // Add publishing logic here (e.g., API call)
    axiosInstance
      .post("/Blogs", formData)
      .then((response) => {
        console.log(response, "from post");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">Publish Blog Post</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="title">
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="body">
            Body
          </label>
          <textarea
            id="body"
            name="body"
            value={formData.body}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            rows={5}
          ></textarea>
        </div>
        <Editor
          content={formData.body}
          setContent={(body) => setFormData({ ...formData, body })}
        />

        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="author">
            Author
          </label>
          <input
            type="text"
            id="author"
            name="author"
            value={formData.author}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="publishedOn">
            Published Date
          </label>
          <input
            type="datetime-local"
            id="publishedOn"
            name="publishedOn"
            value={formData.publishedOn.slice(0, 16)} // formatting for datetime-local input
            onChange={(e) =>
              setFormData({ ...formData, publishedOn: e.target.value })
            }
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="visible"
              checked={formData.visible}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Visible</span>
          </label>
        </div>

        <button
          type="button"
          onClick={handlePublish}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none"
        >
          Publish
        </button>
      </form>
    </div>
  );
};

export default BlogPostForm;
