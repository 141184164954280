import { useState } from "react";
import AppointmentCard from "./AppointmentCard";
import CreateAppointment from "./CreateAppointment";
import Modal from "../../../../utils/Modal";
import Toggler from "../Task/Toggler";

type Appointment = {
  title: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  isAllDay: boolean;
  location: string;
  guests: string;
  sendInvites: boolean;
};

function AppointmentTracker() {
  const [selectedAppointment, setSelectedAppointment] =
    useState<Appointment | null>(null);
  const [appointmentData, setAppointmentData] = useState<Appointment[]>([]); // state for appointments
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);

  const [openSection, setOpenSection] = useState<boolean>(false);

  const toggleSection = () => {
    setOpenSection((prev) => !prev);
  };

  const handleAddAppointment = (appointment: Appointment) => {
    if (selectedAppointment) {
      // Editing existing appointment
      setAppointmentData((prevData) =>
        prevData.map((appt) =>
          appt === selectedAppointment ? appointment : appt
        )
      );
    } else {
      // Creating a new appointment
      setAppointmentData((prevData) => [appointment, ...prevData]);
    }
    setShowAppointmentModal(false);
    setSelectedAppointment(null); // Reset after editing/creating
  };

  const handleEditAppointment = (appointment: Appointment) => {
    setSelectedAppointment(appointment); // Set the clicked appointment for editing
    setShowAppointmentModal(true); // Open modal to edit the appointment
  };

  return (
    <div>
      {" "}
      {/* Appointment Section */}
      <div className="mb-4">
        <Toggler
          openSection={openSection}
          toggleSection={toggleSection}
          setShowModal={setShowAppointmentModal}
          title="Appointment Tracker"
        />
        {openSection && (
          <div className="mt-2 p-2 ">
            {/* Display appointments here */}
            {appointmentData.map((appointment, index) => (
              <div key={index} className="">
                <AppointmentCard
                  onClick={() => handleEditAppointment(appointment)}
                  appointment={appointment}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {showAppointmentModal && (
        <Modal
          isOpen={showAppointmentModal}
          onClose={() => setShowAppointmentModal(false)}
        >
          <CreateAppointment
            initialData={selectedAppointment}
            onCreateAppointment={handleAddAppointment}
          />
        </Modal>
      )}
    </div>
  );
}

export default AppointmentTracker;
