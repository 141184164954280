import React, { useState } from "react";

type CreateNoteProps = {
  onAddNote: (note: string) => void;
};

const CreateNote: React.FC<CreateNoteProps> = ({ onAddNote }) => {
  const [noteContent, setNoteContent] = useState("");

  const handleCreateNote = () => {
    if (noteContent.trim() !== "") {
      onAddNote(noteContent);
      setNoteContent(""); // Reset textarea after submission
    }
  };

  return (
    <div className="border p-4 rounded-md mb-4 bg-white">
      <textarea
        value={noteContent}
        onChange={(e) => setNoteContent(e.target.value)}
        className="w-full p-2 border rounded-md"
        placeholder="Add notes or type @name to notify"
        rows={4}
      />
      <button onClick={handleCreateNote} className="btn-quaternary">
        Create Note
      </button>
    </div>
  );
};

export default CreateNote;
