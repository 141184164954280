import { Link } from "react-router-dom";
// import Profile from
// import TaskTracker from '../InfoCard/Components/TaskTracker';
import SeniorAdvisorProfile from "./advisorAdmin/AdvisorProfile";
import SeniorLeadCardOverview from "../InfoCard/Components/SeniorLeadCardOverview.tsx";
import TaskTracker from "../InfoCard/Components/Task/TaskTracker.tsx";

const advisor = {
  photo: "https://via.placeholder.com/150",
  name: "John Doe",
  title: "Senior Advisor",
  license: " #12345",
};

const SeniorAdvisorDashboard = () => {
  return (
    <div className="container">
      <div className="grid grid-cols-1 md:grid-flow-row md:grid-cols-4 gap-4 mb-4">
        <div className="">
          <SeniorAdvisorProfile advisor={advisor} />
          <Link
            to="/senior-advisor/123"
            className="block btn-primary text-center mt-6"
          >
            Preview Public View
          </Link>
        </div>
        <div className="md:col-span-2">
          <SeniorLeadCardOverview />
        </div>
        <div className="border p-4 rounded-lg shadow-md ">
          <TaskTracker />
        </div>
      </div>
    </div>
  );
};

export default SeniorAdvisorDashboard;
