interface DisplayLeadProps {
  leadData: {
    fname?: string;
    lname?: string;
    email?: string;
    phone?: string;
    address1?: string;
  };
}

const DisplayLead: React.FC<DisplayLeadProps> = ({ leadData }) => {
  return (
    <div className="">
      <div className="flex items-center">
        <img
          className="w-24 h-24 rounded-full"
          src={"https://via.placeholder.com/150"}
          alt="User Avatar"
        />
        {leadData.fname && (
          <span className="font-semibold m-2">{leadData.fname}</span>
        )}
        {leadData.lname && (
          <span className="font-semibold m-2">{leadData.lname}</span>
        )}
      </div>
      <div className="m-3">
        {leadData.phone && <p className="mt-2">{leadData.phone}</p>}
        {leadData.email && <p className="mt-2">{leadData.email}</p>}
        {leadData.address1 && <p className="mt-2">{leadData.address1}</p>}
      </div>
    </div>
  );
};

export default DisplayLead;
