import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

interface Tag {
  id: number;
  name: string;
}

interface CreateTagProps {
  onAddTag: (selectedTags: Tag[]) => void;
  closeModal: () => void;
}

const CreateTag: React.FC<CreateTagProps> = ({ onAddTag, closeModal }) => {
  const [tags] = useState<Tag[]>([
    { id: 1, name: "Hot Seller" },
    { id: 2, name: "Warm Seller" },
    { id: 3, name: "Lease Client" },
    { id: 4, name: "High Value" },
    { id: 5, name: "Low Value" },
  ]);

  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleTagSelection = (id: number) => {
    if (selectedTags.includes(id)) {
      setSelectedTags(selectedTags.filter((tagId) => tagId !== id));
    } else {
      setSelectedTags([...selectedTags, id]);
    }
  };

  const handleSave = () => {
    const selectedTagObjects = tags.filter((tag) =>
      selectedTags.includes(tag.id)
    );
    onAddTag(selectedTagObjects); // Pass only the selected tags to parent
    closeModal();
  };

  const filteredTags = tags.filter((tag) =>
    tag.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="p-4 w-full max-w-lg mx-auto bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Tags</h2>
        <FontAwesomeIcon
          icon={faTimes}
          className="cursor-pointer text-gray-500 hover:text-gray-700"
          onClick={closeModal}
        />
      </div>

      {/* Search Input */}
      <div className="relative mb-4">
        <input
          type="text"
          className="w-full p-2 border rounded-md pl-10"
          placeholder="Search or create a new tag"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <FontAwesomeIcon
          icon={faSearch}
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
        />
      </div>

      {/* List of Tags */}
      <div className="h-40 overflow-y-auto mb-4 border rounded-md">
        <ul>
          {filteredTags.length > 0 ? (
            filteredTags.map((tag) => (
              <li
                key={tag.id}
                className="flex items-center p-2 hover:bg-gray-100"
              >
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={selectedTags.includes(tag.id)}
                  onChange={() => toggleTagSelection(tag.id)}
                />
                <span>{tag.name}</span>
              </li>
            ))
          ) : (
            <li className="p-2 text-gray-500">No matching tags found</li>
          )}
        </ul>
      </div>

      {/* Footer Buttons */}
      <div className="flex justify-end space-x-2">
        <button
          onClick={closeModal}
          className="px-4 py-2 text-gray-600 hover:underline"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className={`px-4 py-2 bg-blue-500 text-white rounded-md ${
            selectedTags.length === 0
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-blue-600"
          }`}
          disabled={selectedTags.length === 0}
        >
          Save Tags
        </button>
      </div>
    </div>
  );
};

export default CreateTag;
