import { useEffect, useState } from "react";
import { ScheduleXCalendar, useCalendarApp } from "@schedule-x/react";
import { CalendarEvent } from "@schedule-x/calendar";

import {
  createViewWeek,
  createViewMonthGrid,
  createViewDay,
  createViewMonthAgenda,
} from "@schedule-x/calendar";
import "@schedule-x/theme-default/dist/index.css";
import { createEventsServicePlugin } from "@schedule-x/events-service";
import { createEventModalPlugin } from "@schedule-x/event-modal";
import { createDragAndDropPlugin } from "@schedule-x/drag-and-drop";

import EventModal from "./EventModal";
import EventUpdate from "./EventUpdate";
import Modal from "../../../../components/utils/Modal";
import CreateEvent from "./CreateEvent";

const defaultEvent: CalendarEvent = {
  id: "default-id",
  title: "Default Title",
  description: "",
  start: "2024-11-25 02:00",
  end: "2024-11-25 03:00",
  location: "",
  people: [],
  status: "default-status",
  // Any other required properties of CalendarEvent can be set here
};

interface EventProp {
  id: string;
  title: string;
  start: string;
  end: string;
  description: string;
  status: string;
}

const Calendar = () => {
  const [showModal, setShowModal] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] =
    useState<CalendarEvent>(defaultEvent);

  const calendar = useCalendarApp({
    views: [
      createViewDay(),
      createViewWeek(),
      createViewMonthGrid(),
      createViewMonthAgenda(),
    ],
    plugins: [
      createEventsServicePlugin(),
      createEventModalPlugin(),
      createDragAndDropPlugin(),
    ],
    events: [
      {
        id: "1",
        title: "Event 1",
        start: "2024-11-6 00:00",
        end: "2024-11-6 02:00",
        description: "Description of event 1",
        status: "confirmed",
      },
      {
        id: "2",
        title: "Event 2",
        start: "2024-11-25 00:00",
        end: "2024-11-25 02:00",
        description: "Description of event 2",
        status: "cancelled",
      },
    ],
    callbacks: {
      onEventClick(calendarEvent) {
        // console.log("onEventClick", calendarEvent);
        // calendar.events.update({
        //   ...calendarEvent,
        //   title: "Updated Event",
        // });
        openModal(calendarEvent);
        // setShowModal(true);
      },
    },
  });

  const handleCreateEvent = () => {
    setShowCreateEvent(true);
    calendar.events.add({
      id: "",
      start: "",
      end: "",
    });
  };

  const openModal = (event: any) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEvent(defaultEvent);
  };

  const handleSave = (updatedEvent: CalendarEvent) => {
    console.log("from handle save", updatedEvent);
    calendar.events.update(updatedEvent);
  };

  const handleDelete = (eventId: string) => {
    calendar.events.remove(eventId);
  };

  useEffect(() => {
    // get all events
    // calendar.eventsService.getAll();
  }, []);

  return (
    <>
      <button onClick={() => handleCreateEvent()}>Create Event</button>
      <ScheduleXCalendar
        calendarApp={calendar}
        // customComponents={{ eventModal: EventModal }}
      />
      {showCreateEvent && (
        <Modal
          isOpen={showCreateEvent}
          onClose={() => setShowCreateEvent(false)}
        >
          <CreateEvent onSave={handleSave} />
        </Modal>
      )}
      {showModal && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <EventUpdate
            calendarEvent={selectedEvent}
            // isNewEvent={isNewEvent}
            onSave={handleSave}
            // onDelete={handleDelete}
            // onCancel={closeModal}
          />
        </Modal>
      )}
    </>
  );
};

export default Calendar;
