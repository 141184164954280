import BannerImg from "../../components/utils/BannerImg";
import BottomBorder from "../../components/utils/BottomBorder";
import bannerImg from "../../assets/banners/Legal & Financial Planning.png";
import img2 from "../../assets/legalAndFinancialImg.jpg";
import BannerHeading from "../../components/utils/BannerHeading";

function LegalAndFinancialPlanning() {
  return (
    <>
      {/* <h1 className="w-full text-right md:w-1/2 font-bold text-4xl my-7 py-7 border-b-4 border-black">
        Legal & Financial Planning for Senior Living
      </h1> */}
      <BannerHeading
        title="Legal & Financial Planning for Senior Living"
        width="2/3"
      />

      <BannerImg imgUrl={bannerImg} />

      <div>
        <div className="container flex flex-col md:flex-row mb-10">
          <div className="w-4/6 px-10">
            <h2 className="text-xxl font-bold my-2 text-left pb-4 w-full">
              Preparing for the Future
            </h2>
            <p>
              At BlueJay®, we understand how crucial it is to have a
              well-organized plan for your future. Whether it's legal
              documentation or financial planning, taking these steps secures
              your wishes and brings peace of mind to you and your loved ones.
              While we provide general guidance and best practices, it's
              important to note that we do not endorse any specific
              professionals or services mentioned here. We aim to offer
              unbiased, unsolicited advice to help you make informed decisions.
              Always consult with a trusted legal or financial professional for
              your specific situation.
            </p>
          </div>
          <div className="w-2/6 mb-5">
            <img
              src={img2}
              alt="legal and financial planning"
              className="mb-7"
            />
          </div>
        </div>
        <BottomBorder width="4/6" right={true} />
      </div>

      <div className="max-w-5xl mx-auto p-8">
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">
            Legal Planning: Organizing Your Affairs
          </h2>
          <p className="mb-4">
            The legal aspect of planning for senior living goes beyond just
            drafting documents; it’s about ensuring that your personal,
            healthcare, and financial wishes are clearly documented and legally
            enforceable. Here's what you should consider:
          </p>

          <h3 className="text-xl font-semibold mb-4">Wills and Trusts</h3>
          <p className="mb-4">
            <strong>Wills:</strong> A will is a legal document that outlines
            your wishes regarding the distribution of your assets and the care
            of any minor children after you pass away. It’s essential to have
            one, as it helps prevent confusion or disputes among your loved
            ones.
          </p>
          <p className="mb-4">
            <strong>Tips:</strong> Review and update your will regularly,
            especially after significant life changes like the birth of a
            grandchild or the sale of property. Choose a trustworthy executor to
            manage your affairs and distribute your assets according to your
            wishes.
          </p>

          <p className="mb-4">
            <strong>Trusts:</strong> Unlike a will, a trust allows your assets
            to be managed during your lifetime and distributed after your death
            without the need for probate, which can be time-consuming and
            costly.
          </p>
          <p className="mb-4">
            {" "}
            <strong>Revocable Living Trust:</strong> This is a flexible option
            where you retain control over your assets during your lifetime and
            can modify the trust as your situation changes. Upon your death, the
            assets are transferred according to your instructions without going
            through probate.{" "}
          </p>
          <p className="mb-4">
            <strong>Irrevocable Trust:</strong> This is more rigid but offers
            specific tax benefits and protections against creditors. Once assets
            are placed in this trust, they generally cannot be altered or
            reclaimed by the grantor.
          </p>
        </section>

        <section className="mb-12">
          <h3 className="text-xl font-semibold mb-4">Power of Attorney</h3>
          <p className="mb-4">
            A Power of Attorney (POA) is a legal document that grants someone
            the authority to act on your behalf. This is crucial if you become
            unable to manage your own affairs due to illness or incapacity.
          </p>

          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>General POA:</strong> This grants broad powers but usually
              becomes void if you are incapacitated.
            </li>
            <li>
              <strong>Durable POA:</strong> This remains effective even if you
              become incapacitated, ensuring that someone you trust can manage
              your affairs.
            </li>
            <li>
              <strong>Healthcare POA:</strong> This allows explicitly someone to
              make medical decisions on your behalf based on your healthcare
              preferences.
            </li>
          </ul>

          <p className="mb-4">
            Make sure the person you appoint understands your values and wishes
            regarding medical treatment.
          </p>
        </section>

        <section className="mb-12">
          <h3 className="text-xl font-semibold mb-4">
            Advance Healthcare Directive
          </h3>
          <p className="mb-4">
            An Advance Healthcare Directive or Living Will outlines your medical
            treatment preferences if you cannot communicate your wishes. This
            may include whether you want life-sustaining measures in certain
            situations, such as ventilators or feeding tubes.
          </p>
          <p className="mb-4">
            <strong>Why It Matters:</strong> This document ensures that your
            healthcare preferences are respected and minimizes the burden on
            loved ones during difficult times. It should be shared with your
            healthcare POA and your primary care provider to ensure that they
            have the most up-to-date version.
          </p>
        </section>

        <section className="mb-12">
          <h3 className="text-xl font-semibold mb-4">
            Estate Planning Documents
          </h3>
          <p className="mb-4">
            Beyond wills and trusts, you may need other estate planning
            documents, such as:
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Beneficiary Designations:</strong> Ensure that all your
              assets, like life insurance and retirement accounts, have
              up-to-date beneficiary designations.
            </li>
            <li>
              <strong>Guardianship Papers:</strong> If you are responsible for a
              dependent or a loved one with special needs, appointing a legal
              guardian is vital to secure their future care.
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            Financial Planning: Preparing for Long-Term Care and Beyond
          </h2>
          <p className="mb-4">
            Financial planning is just as critical as legal planning when
            considering senior living options and long-term care. Here’s how to
            prepare:
          </p>

          <h3 className="text-xl font-semibold mb-4">
            Long-Term Care Planning
          </h3>
          <p className="mb-4">
            Understanding the costs associated with different types of care —
            Independent Living, Assisted Living, Memory Care, or Skilled Nursing
            — helps you make informed financial decisions. Long-term care
            insurance or Medicaid can help cover these costs based on your care
            preferences and financial situation.
          </p>

          <h3 className="text-xl font-semibold mb-4">
            Retirement Income Planning
          </h3>
          <p className="mb-4">
            Map out all sources of income, including Social Security benefits,
            pensions, retirement savings (e.g., 401(k) or IRA), and any
            investments. Understanding your income flow is crucial for planning
            monthly budgets in senior living communities.
          </p>

          <h3 className="text-xl font-semibold mb-4">Maximizing Benefits</h3>
          <p className="mb-4">
            Work with a financial advisor to explore strategies like delaying
            Social Security benefits for a larger monthly payout or investing in
            annuities that provide a steady income.
          </p>

          <h3 className="text-xl font-semibold mb-4">Reverse Mortgages</h3>
          <p className="mb-4">
            For seniors looking to age in place, a reverse mortgage may provide
            a steady income stream by tapping into home equity. While this can
            be helpful, it’s important to understand the risks and weigh the
            pros and cons based on your situation.
          </p>
        </section>

        <section className="mb-12">
          <h3 className="text-xl font-semibold mb-4">
            Seeking Professional Help
          </h3>
          <p className="mb-4">
            Proper financial and legal planning is crucial for ensuring your
            affairs are in order as you age. Work with certified professionals
            who specialize in estate planning, tax planning, and senior care to
            navigate these processes effectively.
          </p>
        </section>

        <section className="mb-12">
          <h4 className="font-lg font-bold">Disclaimer</h4>
          <p className="mb-4">
            At BlueJay®, we aim to provide general advice and best practices for
            your legal and financial planning needs. However, we do not endorse
            or recommend specific professionals or services. The information
            provided here is unbiased and for informational purposes only.
            Always consult a qualified professional for personalized advice.
          </p>
          <h3 className="text-2xl font-semibold mb-4">Final Thoughts</h3>
          <p className="mb-4">
            Proper legal and financial planning is a crucial part of senior
            living. Whether you’re planning to move into a community or age in
            place, organizing your affairs and securing your financial future
            will give you and your loved ones peace of mind. Taking these steps
            ensures your wishes are respected and provides the security you need
            to focus on enjoying your golden years.
          </p>
        </section>
      </div>
    </>
  );
}

export default LegalAndFinancialPlanning;
