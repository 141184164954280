// Preview.tsx
import React from "react";

interface PreviewProps {
  content: string;
}

const Preview: React.FC<PreviewProps> = ({ content }) => {
  return (
    <div className="bg-gray-50 p-4 rounded-md shadow-md mb-4">
      <h2 className="text-xl font-semibold mb-2">Preview</h2>
      <div
        className="prose max-w-none"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default Preview;
