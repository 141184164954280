import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface Task {
  name: string;
  dueDate: string;
  dueTime: string;
}

interface CompletedTasksProps {
  completedTasks: Task[];
}

const CompletedTasks: React.FC<CompletedTasksProps> = ({ completedTasks }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mt-4">
      <button
        onClick={toggleAccordion}
        className="w-full text-left py-2 px-4 bg-gray-200 rounded-lg flex justify-between items-center"
      >
        <span className="">Completed Tasks ({completedTasks.length})</span>
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </button>

      {isOpen && (
        <div className="mt-2 space-y-2">
          {completedTasks.map((task, index) => (
            <div
              key={index}
              className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm flex justify-between"
            >
              <div>
                <p className="text-sm font-medium text-gray-900">{task.name}</p>
                <p className="text-xs text-gray-500">
                  Due: {task.dueDate} at {task.dueTime}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CompletedTasks;
