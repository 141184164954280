import React from "react";
import { useState, useEffect } from "react";
import CommunityDetails from "../../components/community/CommunityDetails.tsx";
import CommunityReviews from "../../components/community/CommunityReviews.tsx";
import FilterComponent from "../../components/filters/FilterComponent.tsx";
import Map from "../../components/map/Map.tsx";
import axiosInstance from "../../components/api/axiosInstance.tsx";
import Pagination from "../../components/utils/Pagination.tsx";
import PremimumSpaceAd from "../../components/utils/PremiumSpaceAd.tsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faHeartBroken } from "@fortawesome/free-solid-svg-icons";

import { useUserContext } from "../../components/context/UserContext.tsx";

interface CommunityData {
  id: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  stateLicense: string;
  description: string;
  latitude: string;
  longitude: string;
  website: string;
  rating: number;
  ratingCount: number;
  active: boolean;
}

const SearchResults: React.FC = () => {
  const [communityData, setCommunityData] = useState<CommunityData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [communitiesPerPage, setcommunitiesPerPage] = useState(4);

  const { likedCommunities, toggleLikeCommunity } = useUserContext();

  const handleLikeClick = (communityId: number) => {
    // this sets the likedCommunities state in the UserContext
    if (toggleLikeCommunity) {
      toggleLikeCommunity(communityId);
    }
  };

  // Calculate the indices for the current page
  const indexOfLastCommunity = currentPage * communitiesPerPage;
  const indexOfFirstCommunity = indexOfLastCommunity - communitiesPerPage;
  const currentCommunities = communityData.slice(
    indexOfFirstCommunity,
    indexOfLastCommunity
  );

  // Calculate total pages
  const totalPages = Math.ceil(communityData.length / communitiesPerPage);

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  // Handle Communities per page change
  const handlecommunitiesPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setcommunitiesPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page
  };

  useEffect(() => {
    axiosInstance
      .post("/Communities", {})
      .then((response) => {
        setCommunityData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching community data:", error);
      });
    console.log("current communitites", currentCommunities);
  }, [likedCommunities]);

  if (!communityData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="container">
        <div className="flex flex-col sm:flex-row search-results space-y-8 sm:space-y-0 sm:space-x-8">
          <FilterComponent />
          <div className="flex flex-col md:w-1/2 ">
            <div className="w-full flex justify-between mb-5 text-xl font-semibold">
              <h2>List of Communities</h2>
              {/* <h2>Reviews from the Web</h2> */}
            </div>

            {currentCommunities.length > 0 &&
              currentCommunities.map((community) => (
                <>
                  <button
                    className="border-primary border-2 flex items-center justify-center w-8 h-8 rounded-xl"
                    onClick={() => handleLikeClick(community.id)}
                  >
                    {likedCommunities?.some(
                      (comm) => comm.Id === community.id
                    ) ? (
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faHeart}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faHeartBroken}
                      />
                    )}
                  </button>
                  <CommunityDetails
                    key={community.id}
                    id={community.id}
                    name={community.name}
                    address1={community.address1}
                    address2={community.address2}
                    city={community.city}
                    state={community.state}
                    zip={community.zip}
                    phone={community.phone}
                    stateLicense={community.stateLicense}
                    description={community.description}
                    latitude={community.latitude}
                    longitude={community.longitude}
                    website={community.website}
                    rating={community.rating}
                    ratingCount={community.ratingCount}
                    active={community.active}
                  />
                </>
              ))}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              itemsPerPage={communitiesPerPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handlecommunitiesPerPageChange}
            />
          </div>
          <div className="w-full md:w-1/4 h-auto ">
            {currentCommunities.length > 0 && (
              <Map
                property={{
                  id: "mock-id",
                  name: currentCommunities[0].name, // Example name for the first community
                  latitude: Number(currentCommunities[0].latitude), // Example latitude for the first community
                  longitude: Number(currentCommunities[0].longitude), // Example longitude for the first community
                  summary: "Main property description",
                }}
                nearbyProperties={currentCommunities.map(
                  (community, index) => ({
                    id: `community-${index}`,
                    name: community.name,
                    latitude: Number(community.latitude),
                    longitude: Number(community.longitude),
                    summary: community.description,
                  })
                )}
              />
            )}
          </div>
        </div>
        <PremimumSpaceAd
          title="Advertise Your Advisors Here"
          content="Reach out to us to learn more about advertising opportunities."
        />
      </div>
    </>
  );
};

export default SearchResults;
