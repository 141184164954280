import React, { useState } from "react";

const faqData = [
  {
    question: "Do you charge a placement fee?",
    answer: "No.",
  },
  {
    question: "Do you charge the senior community a fee?",
    answer: "No.",
  },
  {
    question: "What services do you provide?",
    answer:
      "We offer comprehensive consultation services including setting up campus tours in senior communities and working closely with their in-house sales representatives. Our team will assist you in developing a broader care plan, addressing how to handle your property-whether it's selling, leasing, or any necessary preparations. We aim to support you throughout the transition process, ensuring that every detail is managed effectively.",
  },
  {
    question: "How do you get compensated for your services?",
    answer:
      "Once the property sells, the Senior Advisor is compensated through escrow if you work together on that part. As your real estate broker, they represent you in the sale transaction. All other services, including planning and coordination, are part of a commitment to you.",
  },
  {
    question: "What if I'm not interested in selling my property?",
    answer:
      "That's perfectly fine. There is no pressure or obligation to sell or lease your property. We're here to be a resource, guiding you to the best community, answering all your questions, and providing support regardless of your decision about your property.",
  },
  {
    question: "Why should I choose your services over another senior advisor?",
    answer:
      "You are free to choose any advisor you prefer. However, by working with us, you can access a network of Senior Advisors who do not charge a fee or receive compensation from the communities they recommend. We are happy to give you the best guidance and support to meet your needs.",
  },
];

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto py-4 my-4">
      <div className="w-full h-[0.05px] bg-secondary mb-4"></div>{" "}
      {/* Full-width horizontal bar */}
      <h1 className="text-3xl font-bold mb-4">Frequently Asked Questions</h1>
      <div className="space-y-4">
        {faqData.map((item, index) => (
          <div key={index} className="border-b border-gray-200">
            <button
              onClick={() => toggleAccordion(index)}
              className="w-full text-left py-2 text-lg font-semibold flex justify-between items-center"
            >
              {item.question}
              <span>{activeIndex === index ? "-" : "+"}</span>
            </button>
            {activeIndex === index && (
              <div className="py-2 text-gray-700">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
