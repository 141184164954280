import { Outlet } from "react-router-dom";

const AdminCommunityChampion = () => {
  return (
    <div className="min-h-[800px]">
      Admin community champion
      <Outlet />
    </div>
  );
};

export default AdminCommunityChampion;
