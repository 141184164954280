import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../components/context/UserContext.tsx";
import AdminHeader from "../../components/header/AdminHeader.tsx";
import DrawerContainer from "../../components/admin/DrawerContainer.tsx";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faHeart,
  faCog,
  faCommentAlt,
  faSignOutAlt,
  faPerson,
  faBell,
  faCalendar,
  faTasks,
  faBookAtlas,
} from "@fortawesome/free-solid-svg-icons"; // Importing FontAwesome icons
import { Outlet } from "react-router-dom";

// Define the valid user roles as a union type
type UserRole =
  | "senior-advisor"
  | "care-champion"
  | "community"
  | "super-admin";

// Define the menu configuration structure
interface MenuConfig {
  topBar: MenuItem[];
  verticalBar: { text: string; route: string; icon: string }[];
}
interface MenuItem {
  to: string;
  label: string | React.ReactElement;
  index: number;
  ariaLabel: string;
  dropdown?: {
    to: string;
    label: string;
    icon: IconDefinition;
  }[];
}

// Define the actual menu configuration
const menuConfig: Record<UserRole, MenuConfig> = {
  "super-admin": {
    topBar: [
      {
        to: "/admin",
        label: "Dashboard",
        index: 0,
        ariaLabel: "Dashboard",
      },
      {
        to: "/admin/senior-advisor",
        label: "Senior Advisor",
        index: 1,
        ariaLabel: "Senior Advisor",
      },
      {
        to: "/admin/care-champion",
        label: "Care Champion",
        index: 2,
        ariaLabel: "Care Champion",
      },
      {
        to: "/admin/community-champion",
        label: "Community",
        index: 3,
        ariaLabel: "Community",
      },
      {
        to: "/admin/blog",
        label: "Blog Control",
        index: 4,
        ariaLabel: "Blogs",
      },
    ],
    verticalBar: [
      { text: "Dashboard", route: "/admin", icon: "🏠" },
      {
        text: "Senior Advisor",
        route: "/admin/senior-advisor",
        icon: "👴",
      },
      {
        text: "Care Champion",
        route: "/admin/care-champion",
        icon: "🏆",
      },
      {
        text: "Community",
        route: "/admin/community",
        icon: "🌐",
      },
    ],
  },
  "senior-advisor": {
    topBar: [
      {
        to: "/admin/senior-advisor/dashboard",
        label: "Dashboard",
        index: 0,
        ariaLabel: "Dashboard",
      },
      {
        to: "/admin/senior-advisor/lead-tracker",
        label: "Lead Tracker",
        index: 1,
        ariaLabel: "Lead Tracker",
      },
      {
        to: "/admin/senior-advisor/leads-card",
        label: "Senior Advisor Leads",
        index: 2,
        ariaLabel: "Senior Advisor Leads",
      },
      {
        to: "/admin/senior-advisor/notifications",
        label: (
          <FontAwesomeIcon icon={faBell} size="lg" className="text-gray-700" />
        ),
        index: 3,
        ariaLabel: "Senior Advisor Notifications",
        dropdown: [
          {
            to: "/admin/senior-advisor/calendar",
            label: "Calendar",
            icon: faCalendar,
          },
          {
            to: "/admin/senior-advisor/appointments",
            label: "Appointments",
            icon: faBookAtlas,
          },
          {
            to: "/admin/senior-advisor/messages",
            label: "Message Tracker",
            icon: faTasks,
          },
        ],
      },
      {
        to: "/admin/senior-advisor", // default to dashboard
        label: (
          <FontAwesomeIcon
            icon={faPerson}
            size="lg"
            className="text-gray-700"
          />
        ),
        index: 4,
        ariaLabel: "Account",
        dropdown: [
          {
            to: "/admin/senior-advisor/client-transition-support",
            label: "Client Transition Support",
            icon: faUser,
          },
          {
            to: "/admin/senior-advisor/favorites",
            label: "Favorites",
            icon: faHeart,
          },
          {
            to: "/admin/senior-advisor/settings",
            label: "Account Settings",
            icon: faCog,
          },
          {
            to: "/admin/senior-advisor/messages",
            label: "Messages",
            icon: faCommentAlt,
          },
          {
            to: "/admin/senior-advisor/logout",
            label: "Logout",
            icon: faSignOutAlt,
          },
        ],
      },
    ],
    verticalBar: [
      { text: "Dashboard", route: "/admin/dashboard", icon: "🏠" },
      {
        text: "Tasks & Checklists",
        route: "/admin/tasks",
        icon: "📋",
      },
      {
        text: "Community & Tour Coordination",
        route: "/admin/tour-management",
        icon: "📅",
      },
      {
        text: "Client Transition Support",
        route: "/admin/client-transition-support",
        icon: "🔄",
      },
      {
        text: "Calendar & Appointments",
        route: "/admin/calendar",
        icon: "📁",
      },
      {
        text: "Performance Metrics & Reporting",
        route: "/admin/performance",
        icon: "📊",
      },
      { text: "Settings", route: "/admin/settings", icon: "⚙️" },
    ],
  },
  "care-champion": {
    topBar: [
      {
        to: "/admin/care-champion/dashboard",
        label: "Dashboard",
        index: 0,
        ariaLabel: "Dashboard",
      },

      {
        to: "/admin/care-champion/notifications",
        label: (
          <FontAwesomeIcon icon={faBell} size="lg" className="text-gray-700" />
        ),
        index: 3,
        ariaLabel: "Care Champion Notifications",
        dropdown: [
          {
            to: "/admin/care-champion/Calendar",
            label: "Calendar",
            icon: faCalendar,
          },
          {
            to: "/admin/care-champion/favorites",
            label: "Appointments",
            icon: faBookAtlas,
          },
          {
            to: "/admin/care-champion/messages",
            label: "Message Tracker",
            icon: faTasks,
          },
        ],
      },
      {
        to: "/admin/care-champion", // default to dashboard
        label: (
          <FontAwesomeIcon
            icon={faPerson}
            size="lg"
            className="text-gray-700"
          />
        ),
        index: 4,
        ariaLabel: "Account",
        dropdown: [
          {
            to: "/care-champion/care-champion/about-me",
            label: "About Me",
            icon: faUser,
          },
          {
            to: "/care-champion/care-champion/favorites",
            label: "Favorites",
            icon: faHeart,
          },
          {
            to: "/admin/care-champion/settings",
            label: "Account Settings",
            icon: faCog,
          },
          {
            to: "/admin/care-champion/messages",
            label: "Messages",
            icon: faCommentAlt,
          },
          {
            to: "/admin/care-champion/logout",
            label: "Logout",
            icon: faSignOutAlt,
          },
        ],
      },
    ],
    verticalBar: [
      {
        text: "Dashboard",
        route: "/admin/care-champion/dashboard",
        icon: "🏠",
      },
      {
        text: "Tasks & Checklists",
        route: "/admin/care-champion/tasks",
        icon: "📋",
      },
      {
        text: "Community & Tour Coordination",
        route: "/admin/care-champion/tour-management",
        icon: "📅",
      },
      {
        text: "Client Transition Support",
        route: "/admin/care-champion/client-transition-support",
        icon: "🔄",
      },
      {
        text: "Calendar & Appointments",
        route: "/admin/care-champion/calendar",
        icon: "📁",
      },
      {
        text: "Performance Metrics & Reporting",
        route: "/admin/care-champion/performance",
        icon: "📊",
      },
      { text: "Settings", route: "/admin/care-champion/settings", icon: "⚙️" },
    ],
  },
  community: {
    topBar: [
      {
        to: "/admin/community-champion/dashboard",
        label: "Dashboard",
        index: 0,
        ariaLabel: "Community Dashboard",
      },
      {
        to: "/admin/community-champion/profile",
        label: "Profile ",
        index: 1,
        ariaLabel: "Community profile",
      },
      {
        to: "/admin/community-champion/calendar",
        label: "Calendar",
        index: 2,
        ariaLabel: "Community Calendar",
      },
    ],
    verticalBar: [
      { text: "Community Profile", route: "/community-profile", icon: "" },
    ],
  },
};

const Dashboard: React.FC = () => {
  const { userRole } = useUserContext(); // Assume userRole is provided by UserContext
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (userRole) {
  //     const { topBar } = menuConfig[userRole as UserRole];
  //     // const defaultRoute = verticalBar[0].route;

  //     switch (userRole) {
  //       case "super-admin":
  //         navigate("/admin");
  //         break;
  //       case "senior-advisor":
  //         navigate("/admin/senior-advisor");
  //         break;
  //       case "care-champion":
  //         navigate("/admin/care-champion");
  //         break;
  //       case "community":
  //         navigate("/admin/community-champion");

  //         break;
  //       default:
  //         console.log("No dashboard available for this role");
  //         break;
  //     }
  //   }
  // }, [userRole]);

  // Check if userRole is available; render loading state if not
  if (!userRole) {
    return <div>Loading...</div>; // Or a proper loading spinner
  }

  // Access menu based on userRole, ensure valid role is used
  const { topBar, verticalBar } = menuConfig[userRole as UserRole];

  return (
    <>
      <AdminHeader menuItems={topBar} />
      <DrawerContainer items={verticalBar}>
        <Outlet />
      </DrawerContainer>
    </>
  );
};

export default Dashboard;
