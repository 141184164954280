// BlogControl.tsx
import React, { useState } from "react";
import BlogPost from "./BlogPost";
import Editor from "./Editor";
import Preview from "./Preview";
import "react-quill/dist/quill.snow.css";

const BlogControl: React.FC = () => {
  const [content, setContent] = useState<string>(""); // Editor content

  const handlePublish = () => {
    // Logic to save/publish the blog post (e.g., API call)
    alert("Blog post published!");
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Blog Post Editor</h1>
      <BlogPost />
      {/* <Editor content={content} setContent={setContent} /> */}

      <Preview content={content} />
    </div>
  );
};

export default BlogControl;
