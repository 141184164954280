import React, { useState, useEffect } from "react";

type Appointment = {
  title: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  isAllDay: boolean;
  location: string;
  guests: string;
  sendInvites: boolean;
};

type CreateAppointmentProps = {
  onCreateAppointment: (appointment: Appointment) => void;
  initialData?: Appointment | null; // Optional prop to populate form for editing
};

const CreateAppointment: React.FC<CreateAppointmentProps> = ({
  onCreateAppointment,
  initialData = null,
}) => {
  const [title, setTitle] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [location, setLocation] = useState("");
  const [guests, setGuests] = useState("");
  const [isAllDay, setIsAllDay] = useState(false);
  const [sendInvites, setSendInvites] = useState(false);

  // Use effect to populate form when editing an appointment
  useEffect(() => {
    if (initialData) {
      setTitle(initialData.title);
      setStartDateTime(initialData.startDate + "T" + initialData.startTime);
      setEndDateTime(initialData.endDate + "T" + initialData.endTime);
      setLocation(initialData.location);
      setGuests(initialData.guests);
      setIsAllDay(initialData.isAllDay);
      setSendInvites(initialData.sendInvites);
    }
  }, [initialData]);

  const handleCreateAppointment = (e: React.FormEvent) => {
    e.preventDefault();

    const appointmentData: Appointment = {
      title,
      startDate: startDateTime.split("T")[0],
      startTime: startDateTime.split("T")[1],
      endDate: endDateTime.split("T")[0],
      endTime: endDateTime.split("T")[1],
      location,
      guests,
      isAllDay,
      sendInvites,
    };

    onCreateAppointment(appointmentData); // Pass the appointment data to parent
  };

  return (
    <div className="border p-6 rounded-lg shadow-md ">
      <h2 className="text-xl font-bold mb-4">
        {initialData ? "Edit Appointment" : "Create Appointment"}
      </h2>
      <form onSubmit={handleCreateAppointment} className="space-y-4">
        <div>
          <label className="block text-sm font-medium">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="input-field"
            placeholder="Appointment Title"
            required
          />
        </div>
        <div className="flex space-x-2">
          <div className="w-1/2">
            <label className="block text-sm font-medium">From</label>
            <input
              type="datetime-local"
              value={startDateTime}
              onChange={(e) => setStartDateTime(e.target.value)}
              className="input-field"
              required
            />
          </div>
          <div className="w-1/2">
            <label className="block text-sm font-medium">To</label>
            <input
              type="datetime-local"
              value={endDateTime}
              onChange={(e) => setEndDateTime(e.target.value)}
              className="input-field"
              required
            />
          </div>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={isAllDay}
            onChange={(e) => setIsAllDay(e.target.checked)}
            className="input-checkbox mr-2"
          />
          <label className="text-sm">All Day Event</label>
        </div>
        <div>
          <label className="block text-sm font-medium">Location</label>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="input-field"
            placeholder="Location"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Guests</label>
          <input
            type="text"
            value={guests}
            onChange={(e) => setGuests(e.target.value)}
            className="input-field"
            placeholder="Guests (comma separated emails)"
          />
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={sendInvites}
            onChange={(e) => setSendInvites(e.target.checked)}
            className="input-checkbox mr-2"
          />
          <label className="text-sm">Send Invitation</label>
        </div>
        <button type="submit" className="btn-primary">
          {initialData ? "Update Appointment" : "Create Appointment"}
        </button>
      </form>
    </div>
  );
};

export default CreateAppointment;
