import { Outlet } from "react-router-dom";
import Header from "../../components/header/Header.tsx";

const LayoutWithOutSearchBar = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default LayoutWithOutSearchBar;
