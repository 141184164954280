import React from "react";

const DirectMsg: React.FC = () => {
  return (
    <div className="border p-4 rounded-md mb-4 bg-white">
      <input
        type="text"
        className="w-full p-2 border rounded-md mb-2"
        placeholder="From"
      />
      <input
        type="text"
        className="w-full p-2 border rounded-md mb-4"
        placeholder="To"
      />
      <textarea
        className="w-full p-2 border rounded-md mb-4"
        placeholder="Type your message here..."
      />
      <button className="btn-quaternary">Send Message</button>
    </div>
  );
};

export default DirectMsg;
