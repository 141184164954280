interface TextInputProps {
  label: string;
  name: string;
  value?: string;
  type: string;
  placeholder?: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  className?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  name,
  value,
  type,
  className,
  onChange,
}) => {
  return (
    <label className="block mb-2">
      {label}
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className={className}
      />
    </label>
  );
};

export default TextInput;
