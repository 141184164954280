// Register.tsx
import { useState } from "react";
import Modal from "../../../components/utils/Modal";
import ScrollBox from "../../../components/utils/scrollbox/scrollBox";
import SeniorAdvisorTermsConditions from "../../../components/admin/SeniorAdvisor/seniorAdvisorTermsConditions";

const Register: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Step 1: Verification</h1>

      <h2 className="text-lg font-bold mb-2">Personal Details</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <input
          type="text"
          placeholder="First name"
          className="border border-gray-400 p-2 rounded w-full"
        />
        <input
          type="text"
          placeholder="Last name"
          className="border border-gray-400 p-2 rounded w-full"
        />
      </div>
      <input
        type="tel"
        placeholder="Phone number"
        className="border border-gray-400 p-2 rounded w-full mb-4"
      />
      <input
        type="email"
        placeholder="Email"
        className="border border-gray-400 p-2 rounded w-full mb-6"
      />

      <h2 className="text-xl font-bold mb-4">
        Step 2: Licensing Information and Affiliation
      </h2>
      <p className="text-sm text-gray-500 mb-4">
        DRE License Number: Include your 9-digit Department of Real Estate (DRE)
        license number.
      </p>
      <input
        type="text"
        placeholder="DRE#"
        className="border border-gray-400 p-2 rounded w-full mb-4"
      />

      <label className="block text-gray-700 mb-2">Brokerage affiliation</label>
      <input
        type="text"
        className="border border-gray-400 p-2 rounded w-full mb-4"
      />

      <div className="mb-4">
        <p className="text-gray-700 mb-2">
          Do you hold a Seniors Real Estate Specialist (SRES) designation from
          the National Association of Realtors (NAR)?
        </p>
        <div className="flex items-center mb-2">
          <input type="radio" id="sresYes" name="sres" className="mr-2" />
          <label htmlFor="sresYes" className="text-gray-700">
            Yes
          </label>
        </div>
        <div className="flex items-center">
          <input type="radio" id="sresNo" name="sres" className="mr-2" />
          <label htmlFor="sresNo" className="text-gray-700">
            No
          </label>
        </div>
      </div>

      <div className="my-2">
        <p className="text-gray-700 mb-2">
          Are you willing to become a Seniors Real Estate Specialist (SRES)?
        </p>
        <div className="flex items-center mb-2">
          <input type="radio" id="willingYes" name="willing" className="mr-2" />
          <label htmlFor="willingYes" className="text-gray-700">
            Yes
          </label>
        </div>
        <div className="flex items-center">
          <input type="radio" id="willingNo" name="willing" className="mr-2" />
          <label htmlFor="willingNo" className="text-gray-700">
            No
          </label>
        </div>
      </div>
      <button
        type="submit"
        onClick={() => setModalOpen(true)}
        className="w-full px-4 py-2 font-bold text-white bg-primary rounded-md hover:bg-secondary focus:outline-none focus:ring focus:ring-indigo-200"
      >
        Register
      </button>
      {modalOpen && (
        <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
          <ScrollBox openModal={setModalOpen}>
            <SeniorAdvisorTermsConditions />
          </ScrollBox>
        </Modal>
      )}
    </div>
  );
};

export default Register;
