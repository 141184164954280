// ExpertiseSpecializations.tsx
import React from "react";

const yearsInBusiness = [
  "0-2 years",
  "3-5 years",
  "6-10 years",
  "11-20+ years",
];

const specializations = [
  "Estate Planning Coordination",
  "Downsizing Specialist",
  "Estate Sales Management",
  "Senior Move Manager",
  "Garage Sale Coordination",
  "Organizing Expert",
  "Grief Counseling and Support",
  "Aging-in-Place Modifications",
  "Accessibility Enhancements Consultant",
  "Reverse Mortgage Advisor",
];

const certifications = [
  "SRES (Seniors Real Estate Specialist)",
  "CIPS (Certified International Property Specialist)",
  "CAPS (Certified Aging-in-Place Specialist)",
  "CRS (Certified Residential Specialist)",
  "RSPS (Resort and Second-Home)",
  "ABR (Accredited Buyer’s Representative)",
  "CSA (Certified Senior Advisor)",
  "GRI (Graduate, REALTOR® Institute)",
  "e-PRO®",
  "AHWD (At Home With Diversity® Certification)",
  "GREEN",
  "PSA",
];

const ExpertiseSpecializations: React.FC = () => {
  return (
    <div className="py-6">
      <h2 className="text-2xl font-bold mb-4">
        Step 4: Expertise and Specializations
      </h2>
      <p className="text-gray-700 mb-4">
        <strong>Specializations and Certifications:</strong> Highlight your
        qualifications, especially those relevant to senior living, such as SRES
        (Senior Real Estate Specialist) or CAPS (Certified Aging-in-Place
        Specialist).
      </p>
      {/* Years in Business */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Years in business</h3>
        <div className="border border-gray-300 p-4 rounded-lg">
          <div className="grid grid-cols-2 gap-4">
            {yearsInBusiness.map((year) => (
              <label key={year} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  className="form-checkbox h-[15px] w-[15px] text-blue-600"
                />
                <span className="text-gray-800">{year}</span>
              </label>
            ))}
          </div>
        </div>
      </div>

      {/* Select Specializations */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Select Specializations</h3>
        <div className="border border-gray-300 p-4 rounded-lg">
          <div className="grid grid-cols-3 gap-4">
            {specializations.map((specialization) => (
              <label
                key={specialization}
                className="flex items-center space-x-2"
              >
                <input
                  type="checkbox"
                  className="form-checkbox h-[15px] w-[15px] text-blue-600"
                />
                <span className="text-gray-800">{specialization}</span>
              </label>
            ))}
          </div>
        </div>
      </div>

      {/* Training and Certifications */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">
          Training and Certifications
        </h3>
        <div className="border border-gray-300 p-4 rounded-lg">
          <div className="grid grid-cols-3 gap-4">
            {certifications.map((certification) => (
              <label
                key={certification}
                className="flex items-center space-x-2"
              >
                <input
                  type="checkbox"
                  className="form-checkbox h-[15px] w-[15px] text-blue-600"
                />
                <span className="text-gray-800">{certification}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertiseSpecializations;
