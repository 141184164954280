import React from "react";
import BannerImg from "../../components/utils/BannerImg";
import bannerImg from "../../assets/banners/How it works.png";
import img1 from "../../assets/howItWorksImg.jpg";
// import FAQ from "../components/faq/Faq";
import FAQ from "../../components/faq/FaqNoAccordion";
import BannerHeading from "../../components/utils/BannerHeading";

const HowItWorks: React.FC = () => {
  return (
    <div className="mb-10">
      <div className="w-full overflow-hidden">
        <BannerHeading title="How It Works" width="1/3" />
        <div className="w-full overflow-hidden ">
          <BannerImg imgUrl={bannerImg} />
        </div>
        <div className="container">
          <h3 className="text-2xl font-bold my-4">
            Welcome to Your Trusted Partner in Senior Living
          </h3>
          <p className="mt-3">
            Our dedicated senior advisors are here to assist you every step of
            the way. Whether you're interested in a specific community, need
            guidance on what happens next, or require a sounding board for your
            current situation, we are here to listen and provide support. We
            focus on finding the best community that meets your unique needs and
            preferences.
          </p>
          <p className="mt-3">
            What sets us apart is that our placement services are completely
            free. We do not charge you or collect fees from the communities we
            recommend, ensuring unbiased and honest assistance.
          </p>
          <p className="mt-3">
            Our senior advisors are also Senior Real Estate Specialists (SRES@).
            We understand the complexitites involved in managing your property
            during this transition. Our experienced Senior Advisors are here to
            help with all aspects of this process, providing invaluable support
            as you set up tours, explore communities, and make decisions at your
            own pace.
          </p>

          <p className="mt-3">
            Even if selling or leasing the home isn't on your immediate agenda,
            we're here for you. We aim to nurture our relationship and be a
            reliable resource for the future. When the time comes, you can count
            on us to guide you through every step, always with your best
            interests at heart.
          </p>
          <p className="mt-3">
            Our priority is to ensure you have all the information and resources
            you need to make the best decisions for yourself and your family. At
            the end of the day, it's all about what's best for you.
          </p>
        </div>
      </div>
      <div className="flex justify-around mt-7">
        <h2 className="w-1/4 text-4xl font-bold py-4 my-7 text-right border-t-4 border-black">
          Q & A
        </h2>
        <img
          src={img1}
          alt="board games"
          className="w-1/4 mt-9 rounded-md shadow-md"
        />
      </div>
      <FAQ />
    </div>
  );
};

export default HowItWorks;
