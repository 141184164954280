import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import Locations from "./Locations";
import AreasOfService from "../../../components/admin/SeniorAdvisor/advisorAdmin/AreasOfService";
import ExpertiseSpecializations from "../../../components/admin/SeniorAdvisor/advisorAdmin/ExpertiseSpecializations";

const Settings: React.FC = () => {
  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
      {/* Step 1 */}
      <h2 className="text-2xl font-bold mb-4">Step 1: Your Profile</h2>
      <p className="text-gray-700 mb-2">
        <strong>Your Name and Photo:</strong> Start by entering your full name
        and uploading a professional headshot. This helps build an immediate
        connection with potential clients who value trust and professionalism.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <input
          type="text"
          placeholder="First"
          className="border border-gray-400 p-2 rounded w-full"
        />
        <input
          type="text"
          placeholder="Last"
          className="border border-gray-400 p-2 rounded w-full"
        />
      </div>
      <button className="flex items-center justify-center w-full p-2 border border-gray-400 rounded mb-4 text-gray-700">
        <FontAwesomeIcon icon={faUpload} className="mr-2" />
        Upload Photo
      </button>

      <p className="text-gray-700 mb-2">
        <strong>Contact Information:</strong> Provide your business phone and a
        dedicated email address. These are essential for facilitating direct and
        reliable communication with families and older adults.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <input
          type="tel"
          placeholder="Telephone"
          className="border border-gray-400 p-2 rounded w-full"
        />
        <input
          type="email"
          placeholder="Email address"
          className="border border-gray-400 p-2 rounded w-full"
        />
      </div>

      {/* Step 2 */}
      <h2 className="text-xl font-bold mb-4">Step 2: Share Your Story</h2>
      <p className="text-gray-700 mb-4">
        <strong>Professional Background:</strong> Share your story. Why did you
        choose this path? What experiences have shaped your approach to senior
        real estate?
      </p>
      <textarea
        className="border border-gray-400 p-2 rounded w-full mb-4"
        rows={3}
        placeholder="Senior advising calls to me because..."
      />
      <button className="w-full p-2 border border-gray-400 rounded text-gray-700 mb-4">
        Share Your Story
      </button>

      {/* Step 3 */}

      <AreasOfService />
      <ExpertiseSpecializations />

      {/* Step 4 */}
      <h2 className="text-xl font-bold mb-4">
        Step 4: Build Trust with Testimonials
      </h2>
      <p className="text-gray-700 mb-4">
        <strong>Client Testimonials:</strong> Include positive feedback from
        previous clients. This social proof is invaluable, showcasing your
        ability to empathize with and solve the challenges faced by seniors.
      </p>
      <button className="w-full p-2 border border-gray-400 rounded text-gray-700 mb-6">
        Share Client Feedback
      </button>

      {/* Step 5 */}
      <h2 className="text-xl font-bold mb-4">Step 5: Showcase Your Success</h2>
      <p className="text-gray-700 mb-4">
        <strong>Visual Proof:</strong> Upload before-and-after images of
        properties you’ve worked on, illustrating your ability to transform
        spaces into homes suited for senior living.
      </p>
      <button className="flex items-center justify-center w-full p-2 border border-gray-400 rounded mb-6 text-gray-700">
        <FontAwesomeIcon icon={faUpload} className="mr-2" />
        Upload Images
      </button>

      {/* Step 6 */}
      <h2 className="text-xl font-bold mb-4">Step 6: Go Live</h2>
      <p className="text-gray-700 mb-4">
        <strong>Review and Submit:</strong> Double-check your profile for
        accuracy and completeness. Hit "submit" to send your details for
        approval.
      </p>
      <button className="w-full p-2 bg-primary text-white rounded mb-4">
        Save
      </button>
      <p className="text-gray-500 text-sm">
        <strong>Approval Process:</strong> Once approved, your profile will go
        live, and you’ll officially be part of a pioneering group of advisors
        who are redefining senior living.
      </p>
    </div>
  );
};

export default Settings;
