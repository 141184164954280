import BannerImg from "../../components/utils/BannerImg.tsx";
import bannerImg from "../../assets/banners/Types of Care.png";
import img from "../../assets/typesOfCareImg.jpeg";
import AdvisorsGrid from "../../components/advisor/AdvisorsGrid.tsx";
import BottomBorder from "../../components/utils/BottomBorder.tsx";
import BannerHeading from "../../components/utils/BannerHeading.tsx";

function TypesOfCare() {
  return (
    <>
      <BannerHeading title="Types of Care" width="1/3" />

      <BannerImg imgUrl={bannerImg} />
      <div>
        <div className="container flex flex-col md:flex-row mb-10">
          <div className="w-4/6 px-10">
            <h2 className="text-xxl font-bold my-2 text-left pb-4 w-full">
              Understanding Senior Living Options: Finding the Right Care for
              You
            </h2>
            <p>
              Choosing the right senior living option is a significant decision
              that impacts seniors and their families. At  BlueJay®, we aim to
              provide clarity and guidance as you navigate the different types
              of care available. Whether you or your loved one needs a little
              help with daily activities or specialized memory care,
              understanding each option is the first step in making an informed
              choice.
            </p>
          </div>
          <div className="w-2/6 mb-5">
            <img
              src={img}
              alt="types of care from bluejay"
              className="w-full"
            />
          </div>
        </div>
        <BottomBorder width="4/6" right={true} />
      </div>
      <div className="max-w-5xl mx-auto p-8">
        <h3 className="text-xl font-semibold mt-6 mb-2">Independent Living</h3>
        <p>
          Independent Living communities are designed for active seniors who
          want to enjoy the convenience of community living without home
          maintenance responsibilities. These communities offer private
          apartments or cottages, often with access to various amenities such as
          social activities, community events, transportation services, and
          optional dining plans. It’s an ideal choice for seniors who value
          independence but want to be part of a vibrant, like-minded community.
          By choosing Independent Living, seniors can enjoy the best of both
          worlds—freedom and social engagement—without the hassle of daily
          upkeep.
        </p>
        <h3 className="text-xl font-semibold mt-6 mb-2">Assisted Living</h3>
        <p>
          Assisted Living is a suitable option for seniors who need a bit more
          support with daily tasks but still want to maintain some level of
          independence. Assisted Living communities provide personal care
          services such as help with bathing, dressing, and medication
          management, along with 24-hour staff availability. These communities
          also offer a variety of social and recreational activities, ensuring
          residents remain engaged and active. With additional services like
          transportation and meal preparation, seniors can have the support they
          need while still enjoying their daily routines. This type of care is
          perfect for those who need assistance but prefer to live in a social,
          semi-independent setting.
        </p>
        <h3 className="text-xl font-semibold mt-6 mb-2">Memory Care</h3>
        <p>
          Memory Care is a specialized form of Assisted Living explicitly
          tailored for individuals living with Alzheimer’s, dementia, or other
          memory impairments. These communities provide a secure and safe
          environment designed to minimize wandering and confusion. Staff are
          specially trained in dementia care, offering memory-enhancing
          activities and structured routines that promote a sense of familiarity
          and comfort for residents. In Memory Care communities, seniors receive
          support with daily living activities, such as bathing, dressing, and
          meals, in an environment specifically crafted for their unique needs.
          It’s an ideal option for families seeking expert care in a setting
          designed for safety and engagement.
        </p>
        <h3 className="text-xl font-semibold mt-6 mb-2">
          Skilled Nursing and Rehabilitation
        </h3>
        <p>
          Skilled Nursing communities, also known as nursing homes or
          rehabilitation centers, provide comprehensive medical care and
          rehabilitation services for seniors who require intensive healthcare
          support. These facilities have on-site medical professionals available
          24/7, including nurses and therapists, to assist with medical
          monitoring, medication management, and chronic condition care. Whether
          seniors are recovering from surgery or require long-term medical
          assistance, Skilled Nursing communities offer the necessary support
          for physical rehabilitation, occupational therapy, and speech therapy.
          This level of care is best suited for those who need consistent
          medical attention or are transitioning from hospital care to long-term
          support.
        </p>
        <h3 className="text-xl font-semibold mt-6 mb-2">
          Choosing the Right Care
        </h3>
        <p>
          To help you decide which option best suits your needs, we have created
          a comparison table below that summarizes the features of each type of
          care. You’ll be able to see at a glance what each community offers,
          making it easier to narrow down the right choice for you or your loved
          one. Additionally, we have answered common questions in our FAQ
          section, such as the difference between Assisted Living and
          Independent Living and how to know when Memory Care might be the best
          option.
        </p>
        <h3 className="text-xl font-semibold mt-6 mb-2">
          Next Steps: Finding the Right Community
        </h3>
        <p>
          When ready, you can explore communities that align with your needs.
          Whether you're looking for Independent Living, Assisted Living, or
          Memory Care,  BlueJay®  makes it easy to begin your search. Simply
          click on the options below to view communities tailored to each type
          of care. Alternatively, you can schedule a consultation with one of
          our senior advisors or real estate specialists for personalized
          guidance.
        </p>
        <AdvisorsGrid initialShowSingleAdvisor={true} />
        <h3 className="text-xl font-semibold mt-6 mb-2">
          Additional Resources
        </h3>
        <p>
          Choosing the right senior living option involves financial and legal
          considerations. That’s why we’ve included helpful resources such as
          financing options, preparing for the move, and what to expect in each
          community setting. You can also reach out to a  BlueJay®  advisor for
          a free consultation and personalized support as you navigate this
          process.
        </p>
      </div>
    </>
  );
}

export default TypesOfCare;
