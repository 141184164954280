import React from "react";

interface FollowMeLinksProps {
  links: { platform: string; url: string }[];
}

const FollowMeLinks: React.FC<FollowMeLinksProps> = ({ links }) => {
  const interleavedLinks = links.reduce<React.ReactNode[]>(
    (acc, link, index) => {
      if (index > 0) {
        acc.push(
          <span key={`separator-${index}`} className="mx-2">
            |
          </span>
        );
      }
      acc.push(
        <li key={index} className="inline hover:underline">
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            {link.platform}
          </a>
        </li>
      );
      return acc;
    },
    []
  );

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Follow Me</h3>
      <ul className="flex">{interleavedLinks}</ul>
    </div>
  );
};

export default FollowMeLinks;
