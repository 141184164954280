import React from "react";
import img1 from "../../../assets/freeServices/initialMeetingImg.jpeg";
import img2 from "../../../assets/freeServices/complimentaryAssessmentsImg.jpeg";
import img3 from "../../../assets/freeServices/freeGuidedToursImg.jpg";
import img4 from "../../../assets/freeServices/moveInAssistanceImg.jpg";
import BottomBorder from "../BottomBorder";
import AdvisorsGrid from "../../../components/advisor/AdvisorsGrid";

export const InitialMeeting = () => {
  return (
    <>
      <Heading />
      <div className="container  flex flex-col lg:flex-row my-2 lg:my-9">
        <div className="w-full lg:w-1/2 mx-auto text-left">
          <h2 className="text-3xl font-bold my-3 mb-5">Initial Meeting</h2>
          <img
            src={img1}
            alt="bluejay initial meeting under free services"
            className="w-2/3"
          />
        </div>
        <div className="flex flex-col mx-auto w-full lg:w-1/2">
          <h3 className="text-2xl font-bold my-4">
            Physical and Emotional Needs
          </h3>
          <p className="min-w-full ">
            Bluejay® Senior Advisors will evaluate the senior's physical and
            emotional needs to discuss the appropriate level of care.
          </p>
          <h3 className="text-2xl font-bold my-4">
            Identification of the Ideal Location
          </h3>
          <p className="min-w-full  ">
            Bluejay® Senior Advisors collaborate with the senior and their
            family to identify the most suitable location for a senior living
            community or care facility. This process considers factors such as
            proximity to family and friends, available transportation, and
            personal preferences.
          </p>
        </div>
      </div>
      <BottomBorder width="1/2" right={true} />
      <AdvisorsGrid initialShowSingleAdvisor={true} />
    </>
  );
};

export const ComplimentaryAssessments = () => {
  return (
    <>
      <Heading />
      <div className="container  flex flex-col lg:flex-row my-4 lg:my-9">
        <div className="w-full lg:w-1/2 mx-auto text-left">
          <h2 className="text-3xl font-bold my-3 mb-5">
            Complimentary Assessments
          </h2>
          <img
            src={img2}
            alt="bluejay complimentary assessments under free services"
            className="w-2/3"
          />
        </div>
        <div className="flex flex-col w-full lg:w-1/2">
          <h3 className="text-2xl font-bold mb-4 text-left px-7">
            Activities of Daily Living (ADLs)
          </h3>
          <p className="min-w-full px-7">
            Activities of Daily Living (ADLs) are the essential tasks
            individuals must perform to care for themselves daily. These tasks
            include bathing, dressing, grooming, toileting, mobility, and
            eating. There are Instrumental Activities of Daily Living (IADLs),
            which are tasks necessary for independent living, such as managing
            finances, shopping, meal preparation, and medication management.
          </p>
        </div>
      </div>
      <BottomBorder width="1/2" right={true} />
      <div className="container my-4">
        <h3 className="text-2xl font-bold mb-4 text-left">Assessment</h3>
        <p className="min-w-full px-7 mb-4">
          Evaluating an individual's ability to perform ADLs and IADLs is
          crucial in determining the appropriate level of care and senior living
          community. For example, individuals needing assistance with most or
          all ADLs may require a higher level of care, such as that provided by
          a boarding care facility. Conversely, those who can perform most ADLs
          with minimal support may find assisted living more suitable. For
          independent individuals with ADLs but need help with IADLs, an
          independent living community offering additional services like
          housekeeping, transportation, and meal preparation might be the best
          fit.
        </p>
        <p className="min-w-full px-7 mb-4">
          It is important to recognize that not all senior living communities
          offer the same level of care, and some specialize in supporting
          individuals with specific needs or health conditions. A comprehensive
          assessment of ADLs and IADLs helps identify unique care requirements
          and matches individuals with a senior living community offering the
          appropriate care and services.
        </p>
        <p className="min-w-full px-7 mb-4">
          Our team of Senior Advisors can refer professionals with extensive
          experience in conducting assessments to evaluate an individual's care
          needs and connect them with the right senior living community. We work
          closely with families to understand their loved one's unique
          circumstances and preferences, guiding them toward a community that
          meets their needs and budget.
        </p>
      </div>
    </>
  );
};

export const MoveInAssistance = () => {
  return (
    <>
      <Heading />
      <div className="container flex flex-col md:flex-row my-2 md:my-9">
        <div className="w-full lg:w-1/2 text-left">
          <h2 className="text-3xl font-bold my-3 mb-5">Move-in Assistance</h2>
          <img
            src={img4}
            alt="Bluejay Free services Move in assistance"
            className="w-2/3"
          />
        </div>
        <div className="flex flex-col my-4 w-full lg:w-1/2">
          <h3 className="text-2xl font-bold mb-4 px-7 text-left">
            Move-in Assistance for Seniors
          </h3>
          <p className="min-w-full px-7">
            Bluejay® Senior Advisors can connect you with move-in professionals
            who specialize in offering personalized support for seniors.
          </p>
          <p className="min-w-full px-7 mt-4">
            These experts have the knowledge and experience to help seniors
            transition into assisted living smoothly and with minimal stress.
            They provide assistance with a variety of tasks, including packing,
            organizing, and more.
          </p>
        </div>
      </div>
      <BottomBorder width="3/4" right={true} />
      <div>
        <AdvisorsGrid initialShowSingleAdvisor={true} />
      </div>
    </>
  );
};

export const FreeGuidedTours = () => {
  return (
    <>
      <Heading />
      <div className="container flex flex-col md:flex-row my-2 md:my-9">
        <div className="w-full lg:w-1/2 text-left">
          <h2 className="text-3xl font-bold my-3 mb-5">
            Free{" "}
            <span className="block text-4xl font-bold my-3">Guided Tours</span>
          </h2>
          <img
            src={img3}
            alt="Bluejay Free services Move in assistance"
            className="w-2/3"
          />
        </div>
        <div className="flex flex-col w-full lg:w-1/2 my-4">
          <h3 className="text-2xl font-bold mb-4 px-7 text-left">
            Guided Tours
          </h3>
          <p className="min-w-full px-7">
            A free guided tour with a senior living expert allows seniors to
            explore various communities firsthand and discover the unique
            features and amenities each one provides. In addition to explaining
            the different levels of care available, the expert can address
            questions about staff, facility reputation, and any specialized
            programs or services offered. This personalized insight helps
            seniors and their families decide which facility best meets their
            needs.
          </p>
          <p className="min-w-full px-7 mt-4">
            Overall, a free guided tour with a senior living expert is a
            valuable resource for seniors and their families as they navigate
            the complexities of senior living. It provides peace of mind,
            reassurance, and confidence in the decision-making process, helping
            seniors find a living situation that supports their health,
            well-being, and overall quality of life.
          </p>
        </div>
      </div>
      <BottomBorder width="3/4" right={true} />
      <div>
        <AdvisorsGrid initialShowSingleAdvisor={true} />
      </div>
    </>
  );
};

const Heading = () => {
  return (
    <h1 className="text-4xl text-right font-bold w-full md:w-2/5 border-b-4 border-black pl-16 my-7 pb-4">
      Welcome to Our Free Services Page
    </h1>
  );
};
