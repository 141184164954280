import React from "react";
import { Outlet } from "react-router-dom";

const AdminSeniorAdvisor: React.FC = ({}) => {
  return (
    <div>
      admin senior advisor page
      <Outlet />
    </div>
  );
};

export default AdminSeniorAdvisor;
