import React, { useState } from "react";

interface Lead {
  id: number;
  name: string;
  lastEmail: string;
  lastMessage: string;
  stage: string;
  created: string;
}

const leadsData: Lead[] = [
  {
    id: 1,
    name: "Sherry Daily",
    lastEmail: "An hour ago",
    lastMessage: "6 hours ago",
    stage: "Hot Seller",
    created: "An hour ago",
  },
  {
    id: 2,
    name: "Geoff Goodman",
    lastEmail: "An hour ago",
    lastMessage: "6 hours ago",
    stage: "Hot Seller",
    created: "An hour ago",
  },
  {
    id: 3,
    name: "Peter May",
    lastEmail: "A day ago",
    lastMessage: "2 Days",
    stage: "Aging in Place",
    created: "A day ago",
  },
  {
    id: 4,
    name: "Raul Cruz",
    lastEmail: "A day ago",
    lastMessage: "2 Days",
    stage: "Lease Client",
    created: "A day ago",
  },
];

const LeadTrackerTable: React.FC = () => {
  const [sortOldToNew, setSortOldToNew] = useState(false);
  const [daysLessThan, setDaysLessThan] = useState(7);
  const [daysMoreThan, setDaysMoreThan] = useState<number | "">("");

  const handleSortToggle = () => {
    setSortOldToNew(!sortOldToNew);
  };

  const handleDaysLessThanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDaysLessThan(Number(e.target.value));
  };

  const handleDaysMoreThanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDaysMoreThan(value ? Number(value) : "");
  };

  return (
    <div className="flex p-6 space-x-6">
      {/* Filter and Sort Sidebar */}
      <aside className="w-1/4 bg-white p-4 rounded-lg shadow-md">
        <div className="flex items-center justify-between">
          <span className="text-lg font-semibold">Created</span>
          <button onClick={handleSortToggle} className="text-gray-500 text-lg">
            {sortOldToNew ? "⬆️" : "⬇️"}
          </button>
        </div>
        <div className="mt-2 text-gray-600">
          <p>Sort</p>
          <p className="flex items-center mt-1">
            {sortOldToNew ? "Old ➔ New" : "New ➔ Old"}
          </p>
        </div>
        <div className="mt-4">
          <label className="block text-gray-600">Was less than</label>
          <input
            type="number"
            value={daysLessThan}
            onChange={handleDaysLessThanChange}
            className="mt-1 w-full border border-gray-300 rounded p-2"
          />
          <select className="mt-1 w-full border border-gray-300 rounded p-2">
            <option>Days ago</option>
            <option>Minutes ago</option>
            <option>Hours ago</option>
            <option>Weeks ago</option>
            <option>Months ago</option>
            <option>Years ago</option>
          </select>
        </div>
        <div className="mt-4">
          <label className="block text-gray-600">Was more than</label>
          <input
            type="number"
            value={daysMoreThan}
            onChange={handleDaysMoreThanChange}
            className="mt-1 w-full border border-gray-300 rounded p-2"
          />
          <select className="mt-1 w-full border border-gray-300 rounded p-2">
            <option>Days ago</option>
            <option>Minutes ago</option>
            <option>Hours ago</option>
            <option>Weeks ago</option>
            <option>Months ago</option>
            <option>Years ago</option>
          </select>
        </div>
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-2">Tags</h3>
          <label className="flex items-center space-x-2">
            <input type="radio" name="tags" className="form-radio" />
            <span>Are not empty</span>
          </label>
          <label className="flex items-center space-x-2 mt-2">
            <input type="radio" name="tags" className="form-radio" />
            <span>Include</span>
          </label>
          <button className="mt-2 px-2 py-1 text-sm text-blue-600 border rounded">
            +
          </button>
        </div>
      </aside>

      {/* Leads Table */}
      <div className="w-3/4">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg">
          <thead>
            <tr className="border-b">
              <th className="py-3 px-4 text-left font-semibold">Name</th>
              <th className="py-3 px-4 text-left font-semibold">Last email</th>
              <th className="py-3 px-4 text-left font-semibold">
                Last message
              </th>
              <th className="py-3 px-4 text-left font-semibold">Stage</th>
            </tr>
          </thead>
          <tbody>
            {leadsData.map((lead) => (
              <tr key={lead.id} className="border-b hover:bg-gray-100">
                <td className="py-3 px-4">{lead.name}</td>
                <td className="py-3 px-4">{lead.lastEmail}</td>
                <td className="py-3 px-4">{lead.lastMessage}</td>
                <td className="py-3 px-4">{lead.stage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeadTrackerTable;
